import Button from "react-bootstrap/Button";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import React, { Component } from "react";
import axios from "axios";
import { URL_REGISTER, VERIFY, FIInformation } from "./lib/Constants";
import { STRINGS } from "./lib/Strings";
import NumberFormat from "react-number-format";
import Layout from "./Layout";
import {
  DEFAULT_BRAND,
  DEFAULT_LANGUAGE,
  S_SMART_VERIFY_BRAND,
  S_SMART_VERIFY_LANGUAGE,
} from "../../Communication/Constants";

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const prepareYup = (brand, language) => {
  const brandLang = STRINGS[brand || DEFAULT_BRAND] || {};
  const langStrings = brandLang[language || DEFAULT_LANGUAGE] || {};

  return Yup.object().shape({
    email: Yup.string()
      .email(langStrings.INFO.ERROR_CODE.EMAIL_VALID)
      .max(100, langStrings.INFO.ERROR_CODE.EMAIL_CHARACTER_LIMIT)
      .required(langStrings.INFO.ERROR_CODE.EMAIL_REQUIRED),
    phone: Yup.string()
      .matches(phoneRegExp, langStrings.INFO.ERROR_CODE.PHONE_VALID)
      .required(langStrings.INFO.ERROR_CODE.PHONE_REQUIRED),
    terms: Yup.boolean().oneOf(
      [true],
      langStrings.INFO.ERROR_CODE.TERMS_REQUIRED
    ),
  });
};

class Info extends Component {
  state = {
    verifyError: null,
    showModal: false,
    showSpinner: false,
  };

  componentDidMount() {
    this._ismounted = true;
    const { brand, language } = this.props;
    console.log("componentDidMount", brand, language);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.brand !== this.props.brand ||
      prevProps.language !== this.props.language
    ) {
      // Rebuild the validation schema when the brand or language changes
    }
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  sendVerificationCode(email, phone) {
    const { history, goToPage } = this.props;
    this.setState(
      {
        showSpinner: true,
      },
      () => {
        const msisdn = "1" + phone.replace(VERIFY.CONTACT_NO_PATTERN, "");
        sessionStorage.setItem("EMAIL", email);
        sessionStorage.setItem("PHONE", msisdn);
        axios
          .post(URL_REGISTER, {
            email,
            confirm: email,
            msisdn,
            otpTo: "msisdn",
            smartVerify: true,
          })
          .then((response) => {
            console.log(response);
            sessionStorage.setItem("OTP_ID", response.data.otpId);
            goToPage("/verify");
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            if (this._ismounted) {
              this.setState({
                showSpinner: true,
              });
            }
          });
      }
    );
  }

  render() {
    const { history, brand, language, goToPage } = this.props;
    const { showSpinner } = this.state;

    // Dynamically update validation schema based on session or props values
    const validationSchema = prepareYup(
      sessionStorage.getItem(S_SMART_VERIFY_BRAND) || brand,
      sessionStorage.getItem(S_SMART_VERIFY_LANGUAGE) || language
    );

    return (
      <Layout
        showSpinner={showSpinner}
        history={history}
        brand={brand}
        language={language}
        goToPage={goToPage}
      >
        <Formik
          initialValues={{ email: "", phone: "", terms: false }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            this.sendVerificationCode(values.email, values.phone);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Container className="form-fields inner-smartVerify">
                <Row>
                  <Col sm={12}>
                    <h1>{STRINGS[brand][language].INFO.HEADER_TITLE}</h1>
                  </Col>
                  <Col sm={12}>
                    <h3>{STRINGS[brand][language].INFO.SUBHEADER_TITLE}</h3>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Form.Group controlId="formEmail">
                      <Form.Label>
                        {STRINGS[brand][language].INFO.EMAIL_LABEL}
                      </Form.Label>
                      <Form.Control
                        size="lg"
                        type="email"
                        name="email"
                        placeholder={
                          STRINGS[brand][language].INFO.EMAIL_PLACEHOLDER
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        className={
                          touched.email && errors.email ? "has-error" : null
                        }
                      />
                      {touched.email && errors.email && (
                        <div className="error-message">{errors.email}</div>
                      )}
                    </Form.Group>

                    <Form.Group controlId="formPhone">
                      <Form.Label>
                        {STRINGS[brand][language].INFO.MOBILE_NUMBER_LABEL}
                      </Form.Label>
                      <NumberFormat
                        format={"(###) ###-####"}
                        className={
                          touched.phone && errors.phone
                            ? "form-control form-control-lg has-error"
                            : "form-control form-control-lg"
                        }
                        type="tel"
                        name="phone"
                        value={values.phone}
                        placeholder={
                          STRINGS[brand][language].INFO
                            .MOBILE_NUMBER_PLACEHOLDER
                        }
                        onChange={handleChange}
                      />
                      {touched.phone && errors.phone && (
                        <div className="error-message">{errors.phone}</div>
                      )}
                    </Form.Group>

                    <div className="form-group" controlId="acceptTerms">
                      <div className="form-check">
                        <input
                          className={
                            touched.terms && errors.terms
                              ? "form-check-input has-error"
                              : "form-check-input"
                          }
                          type="checkbox"
                          name="terms"
                          id="terms"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.terms}
                        />
                        <label htmlFor="terms" className="form-check-label">
                          {STRINGS[brand][language].INFO.TERMS_LINK_LABEL_0}{" "}
                          <a
                            href={FIInformation[brand][language].FI_CONDITIONS}
                            target="_blank"
                          >
                            {STRINGS[brand][language].INFO.TERMS_LINK_LABEL_1}
                          </a>
                          {STRINGS[brand][language].INFO.TERMS_LINK_LABEL_2}
                        </label>
                      </div>
                      {touched.terms && errors.terms && (
                        <div className="error-message">{errors.terms}</div>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row className="submit-btn-container">
                  <Col md={{ span: 6, offset: 3 }}>
                    <Button
                      type="submit"
                      variant="primary"
                      block
                      size="lg"
                      disabled={isSubmitting}
                    >
                      {STRINGS[brand][language].INFO.CONTINUE_CTA}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      </Layout>
    );
  }
}

export default Info;
