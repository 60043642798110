import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import qr from "query-string";
import Main from "./Main";
import "../../Styles/smartVerify.scss";
import {
  DEFAULT_BRAND,
  DEFAULT_LANGUAGE,
  S_SMART_VERIFY_BRAND,
  S_SMART_VERIFY_LANGUAGE,
} from "../../Communication/Constants";
import Info from "./Info";
import Verify from "./Verify";
import Review from "./Review";
import { STRINGS } from "./lib/Strings";
import End from "./End";
import Id from "./Id";
import { storeBranding } from "../../Redux/Actions/ApplicationAction";

class SmartVerifyContainer extends Component {
  constructor(props) {
    super(props);
    const sessionBrand =
      sessionStorage.getItem(S_SMART_VERIFY_BRAND) || DEFAULT_BRAND;
    const sessionLanguage =
      sessionStorage.getItem(S_SMART_VERIFY_LANGUAGE) || DEFAULT_LANGUAGE;

    this.state = {
      brand: sessionBrand,
      language: sessionLanguage,
      currentPage: "/",
      code: null,
    };
  }

  componentDidMount() {
    const { doStoreBranding } = this.props;
    const params = qr.parse(this.props.location.search);

    console.log("componentDidMount", params);
    let brand = this.state.brand;
    let language = this.state.language;

    if (params.brand) {
      console.log("brand specified", STRINGS[params.brand]);
      if (STRINGS[params.brand]) {
        console.log("updating brand", params.brand);
        brand = params.brand;
      }
    }

    if (params.language) {
      if (STRINGS[brand][params.language]) {
        language = params.language;
      }
    }

    sessionStorage.setItem(S_SMART_VERIFY_BRAND, brand);
    sessionStorage.setItem(S_SMART_VERIFY_LANGUAGE, language);

    this.setState({ brand, language }, () => {
      doStoreBranding(brand, language);
    });
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  goToPage = (page, code) => {
    console.log("page", page, this);
    this.setState({ currentPage: page, code });
  };

  render() {
    const { brand, language, currentPage, code } = this.state;
    const { history, finInfo } = this.props;

    // Ensure there's always a valid class name
    return (
      <div
        className={`${brand || "default-brand"} ${
          language || "default-language"
        }`}
      >
        {currentPage === "/" && (
          <Main
            history={history}
            brand={brand}
            language={language}
            goToPage={this.goToPage}
          />
        )}
        {currentPage === "/info" && (
          <Info
            history={history}
            brand={brand}
            language={language}
            goToPage={this.goToPage}
          />
        )}
        {currentPage === "/verify" && (
          <Verify
            history={history}
            brand={brand}
            language={language}
            goToPage={this.goToPage}
          />
        )}
        {currentPage === "/review" && (
          <Review
            history={history}
            brand={brand}
            language={language}
            goToPage={this.goToPage}
            finInfo={finInfo}
          />
        )}
        {currentPage === "/id" && (
          <Id
            history={history}
            brand={brand}
            language={language}
            goToPage={this.goToPage}
          />
        )}
        {currentPage === "/end" && (
          <End
            history={history}
            brand={brand}
            language={language}
            goToPage={this.goToPage}
            code={code}
          />
        )}
      </div>
    );
  }
}

SmartVerifyContainer.propTypes = {
  history: PropTypes.object,
  doStoreBranding: PropTypes.func.isRequired,
  finInfo: PropTypes.object,
};

SmartVerifyContainer.defaultProps = {
  categoryList: [],
  productList: [],
};

const mapStateToProps = (state) => ({
  vaultProductList: state.VaultReducer.vaultProductList,
  productList: state.MarketplaceReducer.productList,
  categoryList: state.MarketplaceReducer.categoryList,
  loanProducts: state.LoanDetailsReducer.loanProducts,
  finInfo: state.MarketplaceReducer.finInfo,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doStoreBranding: storeBranding,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmartVerifyContainer);
