import React, { Component } from "react";

class Footer extends Component {
  render() {
    const brand = "abc";
    return (
      <div className="footer mt-auto">
        <p>powered by Mobetize v{process.env.REACT_APP_VERSION}</p>
      </div>
    );
  }
}

export default Footer;
