import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import * as ROUTES from "Constants/Routes";
import AppConstants from "Constants/AppConstants";
import STRINGS from "Constants/Strings";
import MODALMESSAGES from "Constants/Messages";

import PopupModal from "Components/Common/PopupModal/PopupModal";
import { S_APPLICANT_ID, HTTP_STATUS } from "Communication/Constants";

import { callCreateInverite } from "Components/Inverite/Actions/InveriteAction";
import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import BusinessKyc from "./BusinessKyc";
import {
  storeFilterBankingType,
  updateProductToVault,
} from "../MarketPlace/Action/MarketPlaceAction";
import withBusinessHOC from "../../Hoc/BusinessHOC";
import InveriteAccountList from "../Inverite/InveriteAccountList";
import Inverite from "../Inverite/Inverite";
import {
  doAddressKey,
  doCreateApplication,
  doGetAddress,
} from "../AboutYou/Actions/AboutYouActions";
import { searchMember } from "../../Redux/Actions/ApplicantAction";
import {
  getApplicant,
  updateApplication,
} from "../../Redux/Actions/ApplicationAction";
import updateApplicantData from "../../Redux/Actions/UpdateApplicantAction";
import {
  S_INSTNT_FINGER_PRINT,
  S_INSTNT_TXN_ID,
  S_PERSONAL,
} from "../../Communication/Constants";
import { applicantCreateRequest } from "../JointApplicant/Actions/JointApplicantAction";
import { cleanPhone, getPersonalId } from "../../Utils/CommonUtilities";
import { InstntSignupProvider } from "@instnt/instnt-react-js";
import { updatePersonal } from "../../Redux/Actions/BusinessAction";
import { getFingerPrint } from "../../Utils/LogicUtilities";

class BusinessKycContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      showModal: false,
      iframeModal: false,
      showRequired: false,
      requiredList: null,
      iframeUrl: "",
      modalType: "",
      modal: {
        title: "",
        description: "",
        description2: null,
      },
      showVaultLimitError: false,
      showNonMember: false,
      instnttxnid: null,
      fingerprint: null,
    };
  }

  componentDidMount() {
    const {
      doGetProductFromVault,
      products,
      personalData,
      doGetApplicant,
    } = this.props;

    if (products.length <= 0) {
      doGetProductFromVault((response) => {
        if (
          !response ||
          response.data?.length <= 0 ||
          sessionStorage.getItem(S_APPLICANT_ID)
        ) {
          const { history } = this.props;
          history.push(ROUTES.VAULT_DASHBOARD);
        }
      });
    }

    if (!personalData.id) {
      const personalId = sessionStorage.getItem(S_PERSONAL);
      console.log("personalId", personalId);
      if (personalId && personalId !== "null") {
        doGetApplicant({ id: personalId, isPersonalData: true });
      }
    }
  }

  showModal = () => {
    this.setState({
      showModal: true,
      modalType: AppConstants.MODALTYPE.INFORMATION,
      modal: {
        title: MODALMESSAGES.EXISTINGMEMBER.SUCCESS_TITLE,
        description: MODALMESSAGES.EXISTINGMEMBER.SUCCESS_MSG,
      },
    });
  };

  handleContinue = (data) => {
    const {
      continuehandler,
      doUpdateApplicant,
      doGetApplicant,
      doApplicantCreateRequest,
      applicantData,
      doUpdatePersonal,
      personalData,
      getActiveProduct,
      products,
    } = this.props;
    const { instnttxnid, fingerprint } = this.state;

    if (personalData?.id) {
      doUpdateApplicant(data, personalData?.id, (res) => {
        console.log(res);
        if (res.status === HTTP_STATUS.OK) {
          doGetApplicant({ id: personalData?.id, isPersonalData: true });
          continuehandler(null, AppConstants.APPLICATIONSTEP.BUSINESS_KYC);
        }
      });
    } else {
      const activeProduct = getActiveProduct(products);
      let selectedMember = activeProduct?.foundMemberList?.find(
        (x) => x.selectedMember
      );
      if (!selectedMember) {
        selectedMember = activeProduct?.additionalMemberList?.find(
          (x) => x.selectedMember
        );
      }
      if (selectedMember?.authorizedSignatory) {
        doApplicantCreateRequest(
          {
            ...data,
            applicationId: activeProduct.applicationId,
            branch: applicantData.member.branch,
            reasonOpened: applicantData.member.reasonOpened,
            relationship: "owner",
            instntTxnId: instnttxnid,
            fingerprint: fingerprint || getFingerPrint(),
            email: applicantData.member.email,
            msisdn: selectedMember
              ? "1" + cleanPhone(selectedMember.mobileNo)
              : "1" +
                applicantData.address.currentAddressPhoneArea +
                applicantData.address.currentAddressPhone,
          },
          (res) => {
            console.log(res);
            if (res.status === HTTP_STATUS.OK) {
              doUpdatePersonal(
                {
                  personalId: res.data.applicantId,
                },
                () => {
                  sessionStorage.setItem(S_PERSONAL, res.data.applicantId);
                  doGetApplicant(
                    {
                      id: res.data.applicantId,
                      isPersonalData: true,
                    },
                    () => {
                      continuehandler(
                        null,
                        AppConstants.APPLICATIONSTEP.BUSINESS_KYC
                      );
                    }
                  );
                }
              );
            }
          }
        );
      } else {
        this.storeKycData({ ...data });
      }
    }
  };

  storeKycData = (data) => {
    const {
      continuehandler,
      applicantData,
      doUpdateApplication,
      getActiveProduct,
      products,
    } = this.props;
    console.log("storeKycData", data);
    const activeProduct = getActiveProduct(products);
    let applicantInfo = `First name: ${data.firstName}<br/>Middle name: ${
      data.middleName || ""
    }<br/>Last Name: ${data.lastName}<br/>Date Of Birth: ${
      data.dateOfBirth
    }<br/>`;
    applicantInfo += `Address: ${data.address}<br/>City: ${data.city}<br/>Province: ${data.state}<br/>Postal code: ${data.postalCode}<br/>`;
    if (data?.mailingSameAsCivic) {
      applicantInfo += `Mailing address the same: Yes<br/>`;
    } else {
      applicantInfo += `Mailing Address: ${data.mailingAddressLine1}<br/>Mailing City: ${data.mailingCity}<br/>Mailing Province: ${data.mailingProvince}<br/>Mailing Postal code: ${data.mailingPostalCode}<br/>`;
    }
    const applicationComments = `Applicant info: ${applicantInfo}`;
    doUpdateApplication(
      activeProduct.applicationId,
      {
        applicationId: activeProduct.applicationId,
        applicationComments,
      },
      (res) => {
        console.log(res);
        if (res.status === HTTP_STATUS.OK) {
          continuehandler(
            { personal: data },
            AppConstants.APPLICATIONSTEP.BUSINESS_KYC
          );
        }
      }
    );
  };

  toggleModal = () => {
    this.setState({
      showModal: false,
      iframeModal: false,
      showRequired: false,
    });
  };

  onEventHandler = (event) => {
    console.log("Instnt event: ", event?.type);
    let fingerprint = sessionStorage.getItem(S_INSTNT_FINGER_PRINT);
    switch (event.type) {
      case "transaction.initiated":
        console.log("new instnttxnid", event.data.instnt.instnttxnid);
        sessionStorage.setItem(
          S_INSTNT_TXN_ID,
          process.env.REACT_APP_TEST
            ? process.env.REACT_APP_TEST
            : event.data.instnt.instnttxnid
        );
        this.setState({
          instnttxnid: process.env.REACT_APP_TEST
            ? process.env.REACT_APP_TEST
            : event.data.instnt.instnttxnid,
        });
        const fingerPrintTxt = document.getElementById("fingerprint_txt");
        if (fingerPrintTxt?.value) {
          fingerprint = fingerPrintTxt.value;
          sessionStorage.setItem(S_INSTNT_FINGER_PRINT, fingerprint);
          this.setState({ fingerprint });
        } else if (event.data.instnt.fingerprint_txt) {
          fingerprint = event.data.instnt.fingerprint_txt;
          sessionStorage.setItem(S_INSTNT_FINGER_PRINT, fingerprint);
          this.setState({ fingerprint });
        } else if (window.instnt.fingerprint_txt) {
          fingerprint = window.instnt.fingerprint_txt;
          sessionStorage.setItem(S_INSTNT_FINGER_PRINT, fingerprint);
          this.setState({ fingerprint });
        } else if (fingerprint) {
          this.setState({ fingerprint });
        }
        break;
      case "document.captured":
        this.setState({
          documentSettings: event.data.documentSettings,
          selfieSettings: event.data.selfieSettings,
          captureResult: event.data.captureResult,
          doCapture: false,
        });
        break;
      case "document.capture-cancelled":
        this.setState({ doCapture: false });
        break;
      default:
        console.log("unhandled instnt event ", event?.type);
    }
  };

  render() {
    const {
      showModal,
      modalType,
      modal,
      iframeModal,
      iframeUrl,
      choice,
      showRequired,
      requiredList,
      showNonMember,
    } = this.state;
    const {
      productList,
      products,
      getActiveProduct,
      vaultProductList,
      finInfo,
      doAddressKey,
      doGetAddress,
      doSearchMember,
      getDataFromServer,
      personalData,
      provinceList,
      handleBack,
    } = this.props;

    const activeProduct = getActiveProduct(products);

    return (
      <div className="[ about-you-container ]">
        <InstntSignupProvider
          formKey={process.env.REACT_APP_INSTNT_KEY}
          onEvent={this.onEventHandler}
          serviceURL={process.env.REACT_APP_INSTNT_URL}
          idmetrics_version={process.env.REACT_APP_METRICS_VERSION}
        >
          <BusinessKyc
            getActiveProduct={getActiveProduct}
            productList={productList}
            vaultProductList={vaultProductList}
            handleContinue={this.handleContinue}
            activeProduct={activeProduct}
            finInfo={finInfo}
            doAddressKey={doAddressKey}
            doGetAddress={doGetAddress}
            doSearchMember={doSearchMember}
            getDataFromServer={getDataFromServer}
            applicantData={personalData}
            provinceList={provinceList}
            handleBack={handleBack}
          />
        </InstntSignupProvider>
      </div>
    );
  }
}

BusinessKycContainer.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
  doGetAcoountDetails: PropTypes.func.isRequired,
  doCreateInverite: PropTypes.func.isRequired,
  doGetProductFromVault: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  inSessionJointApplicant: PropTypes.object,
  continuehandler: PropTypes.func.isRequired,
};

BusinessKycContainer.defaultProps = {
  history: null,
  products: [],
  inSessionJointApplicant: null,
  finInfo: {},
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
  productList: state.MarketplaceReducer.productList,
  vaultProductList: state.VaultReducer.vaultProductList,
  loanProducts: state.LoanDetailsReducer.loanProducts,
  finInfo: state.MarketplaceReducer.finInfo,
  bankingType: state.MarketplaceReducer.bankingType,
  personalData: state.ApplicationReducer.personalData,
  applicantData: state.ApplicationReducer.response,
  provinceList: state.AboutYouReducer.provinceList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doCreateInverite: (callback) => dispatch(callCreateInverite(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
    doStoreFilterBankingType: (bankingType) =>
      dispatch(storeFilterBankingType(bankingType)),
    doAddressKey: (callback) => dispatch(doAddressKey(callback)),
    doGetAddress: (params, callback) =>
      dispatch(doGetAddress(params, callback)),
    doSearchMember: (params, callback) =>
      dispatch(searchMember(params, callback)),
    doGetApplicant: (payLoad, callback) =>
      dispatch(getApplicant(payLoad, callback)),
    doCreateNewApplicant: (request, callback) =>
      dispatch(doCreateApplication(request, callback)),
    doUpdateApplicant: (request, applicantId, callback) =>
      dispatch(updateApplicantData(request, applicantId, callback)),
    doApplicantCreateRequest: (request, callback) =>
      dispatch(applicantCreateRequest(request, callback)),
    doUpdatePersonal: (payLoad, callback) =>
      dispatch(updatePersonal(payLoad, callback)),
    doUpdateApplication: (applicationId, payLoad, callback) =>
      dispatch(updateApplication(applicationId, payLoad, callback)),
  };
};

const BusinessExistWrapper = withBusinessHOC(BusinessKycContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessExistWrapper);
