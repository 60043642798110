import Header from "./Header";
import Footer from "./Footer";
import React from "react";
import AuxHoc from "./hoc/AuxHoc";
import Spinner from "react-bootstrap/Spinner";

const Layout = (props) => (
  <AuxHoc>
    {props.showSpinner && (
      <div className="progress-indicator-container">
        <Spinner sm={12} animation="border" role="status"></Spinner>
        <span className="progress-message">{props.spinnerMessage}</span>
      </div>
    )}
    <Header
      history={props.history}
      atEnd={props.atEnd}
      brand={props.brand}
      language={props.language}
      goToPage={props.goToPage}
    />
    {props.children}
    <Footer brand={props.brand} language={props.language} />
  </AuxHoc>
);

export default Layout;
