import * as actionTypes from "Redux/Actions/actionTypes";
import {
  GET_VAULT_MEMBER,
  STORE_BRANDING,
  STORE_MEMBERSHIPS_DATA,
  UPDATE_VAULT_MEMBER,
} from "../Actions/actionTypes";
import {
  DEFAULT_BRAND,
  DEFAULT_LANGUAGE,
  S_APPLICANT_CIF_ID,
  S_APPLICANT_ID,
  S_APPLICANT_MEMBER_NO,
  S_QR_KYC,
} from "../../Communication/Constants";

const initialState = {
  response: null,
  gettingStarted: null,
  error: null,
  applicationProgress: [
    {
      productIndex: null,
      productId: null,
      productName: "",
      applicantId: null,
      applicationId: null,
      applicationStep: {
        index: null,
        step: "",
        active: false,
      },
    },
  ],
  isEdit: false,
  isEditPage: null,
  isEditConfirm: false,
  applicationCompleteList: [],
  applicationAppliedList: [],
  templates: [],
  processTemplate: { flow: [] },
  scanDocument: false,
  isNotFirst: false,
  sessionCompletedProducts: [],
  slots: [],
  doximProducts: [],
  isDebitCardEnabled: false,
  applicationId: null,
  eSignatureEnabled: false,
  applicationList: [],
  actionBack: false,
  inReview: false,
  uploadedAt: null,
  disableBack: false,
  msisdn: null,
  vaultMember: {},
  personalData: {},
  membershipsData: [],
  isQrKyc: false,
  brand: DEFAULT_BRAND,
  language: DEFAULT_LANGUAGE,
};

const ApplicationReducer = (state = initialState, action) => {
  const mainApplicantId = sessionStorage.getItem(S_APPLICANT_ID);
  switch (action.type) {
    case actionTypes.STORE_APPLICANT_RESPONSE:
      if (!mainApplicantId || mainApplicantId === action.response.id) {
        sessionStorage.setItem(
          S_APPLICANT_MEMBER_NO,
          action.response.member.memberNumber
        );
        sessionStorage.setItem(S_APPLICANT_CIF_ID, action.response.cifId);
      }
      return {
        ...state,
        response: { ...state.response, ...action.response },
        error: null,
      };
    case actionTypes.STORE_PERSONAL_DATA:
      return {
        ...state,
        personalData: { ...state.response, ...action.response },
      };
    case `${actionTypes.CALL_COMPLETE_APPLICANT_API}_SUCCESS`:
      if (!mainApplicantId || mainApplicantId === action.response.id) {
        sessionStorage.setItem(
          S_APPLICANT_MEMBER_NO,
          action.response.member.memberNumber
        );
        sessionStorage.setItem(S_APPLICANT_CIF_ID, action.response.cifId);
      }
      return {
        ...state,
        response: action.response,
        error: null,
      };
    case actionTypes.STORE_GETTING_STARTED:
      return {
        ...state,
        gettingStarted: action.data,
        error: null,
      };

    case actionTypes.STORE_APPLICATION_STEPS:
      return {
        ...state,
        applicationProgress:
          action.response && action.response.length > 0
            ? action.response
            : [...initialState.applicationProgress],
        error: null,
      };
    case actionTypes.STORE_APPLICATION_PROGRESS_REQUEST:
      return {
        ...state,
        applicationProgress: action.request,
      };
    case actionTypes.APPLICATION_STEPS_ERROR:
      return {
        ...state,
        error: action.error,
        response: null,
      };
    case actionTypes.GETTING_STARTED_ERROR:
      return {
        ...state,
        error: action.error,
        response: null,
      };
    case actionTypes.APPLICATION_ERROR:
      return {
        ...state,
        error: action.error,
        response: null,
      };
    case actionTypes.TOGGLE_EDIT_FLAG:
      return {
        ...state,
        isEdit:
          typeof action.flag == "boolean" ? action.flag : !!action.flag.isEdit,
        isEditPage:
          typeof action.flag == "boolean" ? null : action.flag.isEditPage,
      };
    case actionTypes.TOGGLE_EDIT_CONFIRM_FLAG:
      return {
        ...state,
        isEditConfirm: action.flag,
      };
    case actionTypes.DISABLE_BACK:
      return {
        ...state,
        disableBack: action.flag,
      };
    case actionTypes.STORE_APPLICATION_LIST:
      return {
        ...state,
        applicationCompleteList: action.data.filter(
          (application) =>
            application.inReview ||
            application.uploadedAt ||
            application.submittedToDecisionAt ||
            (application.appliedAt && application.doximProducts.length > 0)
        ),
        applicationCompleteListDashboard: action.data.filter(
          (application) =>
            (application.inReview ||
              application.uploadedAt ||
              application.submittedToDecisionAt ||
              !application.inReview ||
              !application.uploadedAt ||
              (!application.submittedToDecisionAt &&
                application.slots.length > 1)) &&
            application.doximProducts.length > 0
        ),
        applicationAppliedList: action.data.filter(
          (application) => application.appliedAt
        ),
        applicationList: action.data,
      };
    case actionTypes.RESET:
      return initialState;
    case actionTypes.STORE_GET_PRODUCT_TEMPLATE:
      return {
        ...state,
        templates: action.data,
        processTemplate: action.data?.find(
          (template) => template.templateId === action.product.templateId
        ),
      };
    case actionTypes.UPDATE_PRODUCT_TEMPLATE:
      return {
        ...state,
        processTemplate: state.templates.find(
          (templateFlow) =>
            templateFlow.templateId === action.activeProduct.templateId
        ),
      };
    case actionTypes.TOGGLE_SCAN_FLAG:
      return {
        ...state,
        scanDocument: action.flag,
      };
    case actionTypes.TOGGLE_KYC_FLAG:
      if (action.flag) {
        sessionStorage.setItem(S_QR_KYC, "true");
      } else {
        sessionStorage.removeItem(S_QR_KYC);
      }
      return {
        ...state,
        isQrKyc: action.flag,
      };
    case actionTypes.SET_NOT_FIRST_FLAG:
      return {
        ...state,
        isNotFirst: action.flag,
      };
    case actionTypes.STORE_COMPLETED_PRODUCT_IN_A_SESSION:
      return {
        ...state,
        sessionCompletedProducts: state.sessionCompletedProducts.concat(
          action.product
        ),
      };
    case actionTypes.GET_APPLICATION_PROPERTY_SUCCESS:
      return {
        ...state,
        error: "",
        success: true,
        inReview: action.response.inReview,
        uploadedAt: action.response.uploadedAt,
        submittedToDecisionAt: action.response.submittedToDecisionAt,
        slots: action.response.slots,
        doximProducts: action.response.doximProducts,
        applicationId: action.applicationId,
      };
    case actionTypes.GET_APPLICATION_PROPERTY_ERROR:
      return {
        ...state,
        error: action.error,
        success: "",
        appProperties: [],
        doximProducts: [],
      };
    case actionTypes.SET_E_SIGNATURE_ENABLED:
      return {
        ...state,
        eSignatureEnabled: action.flag,
      };
    case actionTypes.STORE_PRODUCT_TEMPLATE:
      return {
        ...state,
        templates: action.templates,
        processTemplate: action.processTemplate,
      };
    case actionTypes.TOGGLE_BACK_FLAG:
      return {
        ...state,
        actionBack: action.flag,
      };
    case `${actionTypes.GET_OCCUPATION_CODES}_SUCCESS`:
      return {
        ...state,
        occupationCodes: action.response,
      };
    case `${actionTypes.GET_REASON_CODES}_SUCCESS`:
      return {
        ...state,
        reasonCodes: action.response,
      };
    case `${actionTypes.GET_COUNTRIES_TAX}_SUCCESS`:
      return {
        ...state,
        countriesTax: action.response,
      };
    case `${GET_VAULT_MEMBER}_SUCCESS`:
    case `${UPDATE_VAULT_MEMBER}_SUCCESS`:
      return {
        ...state,
        vaultMember: action.data || null,
      };
    case `${STORE_MEMBERSHIPS_DATA}`:
      return {
        ...state,
        membershipsData: action.data || [],
      };
    case `${STORE_BRANDING}`:
      console.log("here we go", action);
      return {
        ...state,
        brand: action.brand,
        language: action.language,
      };
    default:
      return state;
  }
};

export default ApplicationReducer;
