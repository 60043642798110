import Button from "react-bootstrap/Button";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import React, { Component } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import { API_KEY, URL_REGISTER, URL_VERIFY } from "./lib/Constants";
import { STRINGS } from "./lib/Strings";
import Layout from "./Layout";
import { isMobile } from "react-device-detect";
import {
  DEFAULT_BRAND,
  DEFAULT_LANGUAGE,
  S_SMART_VERIFY_BRAND,
  S_SMART_VERIFY_LANGUAGE,
} from "../../Communication/Constants";

const prepareYup = (brand, language) => {
  if (STRINGS[brand]) {
    return Yup.object().shape({
      code: Yup.string().required(
        STRINGS[brand][language]?.VERIFY?.ERROR?.OTP_REQUIRED ||
          "OTP is required."
      ),
    });
  }
  return null;
};

class Verify extends Component {
  state = {
    verifyError: null,
    showModal: false,
    showSpinner: false,
    validationSchema: null,
  };

  componentDidMount() {
    const { goToPage } = this.props;
    const otpId = sessionStorage.getItem("OTP_ID");
    const brand = sessionStorage.getItem(S_SMART_VERIFY_BRAND) || DEFAULT_BRAND;
    const language =
      sessionStorage.getItem(S_SMART_VERIFY_LANGUAGE) || DEFAULT_LANGUAGE;

    if (!otpId || !sessionStorage.getItem("EMAIL")) {
      goToPage("/");
      return;
    }
    this.setState({ validationSchema: prepareYup(brand, language) });
  }

  componentDidUpdate(prevProps) {
    const { brand, language } = this.props;

    if (prevProps.brand !== brand || prevProps.language !== language) {
      this.setState({
        validationSchema: prepareYup(brand, language),
      });
    }
  }

  verifyCode(otp, setFieldValue, setSubmitting) {
    const { goToPage } = this.props;
    this.setState({ showSpinner: true }, () => {
      const otpId = sessionStorage.getItem("OTP_ID");
      axios
        .post(URL_VERIFY, { otpId, otp, apiKey: API_KEY })
        .then((response) => {
          sessionStorage.setItem("TOKEN", response.data.token);
          goToPage(isMobile ? "/id" : "/review");
        })
        .catch((error) => {
          console.log("Error verifying code:", error);
          setFieldValue("code", "");
          setSubmitting(false);
          this.setState({ showModal: true });
        })
        .finally(() => {
          this.setState({ showSpinner: false });
        });
    });
  }

  resend() {
    this.setState({ verifyError: null, showSpinner: true }, () => {
      const email = sessionStorage.getItem("EMAIL");
      const msisdn = sessionStorage.getItem("PHONE");
      axios
        .post(URL_REGISTER, {
          email,
          confirm: email,
          msisdn,
          otpTo: "msisdn",
          apiKey: API_KEY,
        })
        .then((response) => {
          sessionStorage.setItem("OTP_ID", response.data.otpId);
        })
        .catch((error) => {
          console.log("Error resending OTP:", error);
        })
        .finally(() => {
          this.setState({ showSpinner: false });
        });
    });
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  render() {
    const { brand, language, goToPage, history } = this.props;
    const { showSpinner, showModal, validationSchema } = this.state;

    return (
      <Layout
        showSpinner={showSpinner}
        history={history}
        brand={brand}
        language={language}
        goToPage={goToPage}
      >
        <Modal
          show={showModal}
          size="sm"
          centered
          onHide={this.handleClose.bind(this)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {STRINGS[brand][language]?.VERIFY?.ERROR?.ERROR_TITLE || "Error"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {STRINGS[brand][language]?.VERIFY?.ERROR?.ERROR_BODY_0 ||
                "An error occurred."}
            </p>
            <p>{STRINGS[brand][language]?.VERIFY?.ERROR?.ERROR_BODY_1 || ""}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose.bind(this)}>
              {STRINGS[brand][language]?.MODAL?.OK || "OK"}
            </Button>
          </Modal.Footer>
        </Modal>
        <Formik
          initialValues={{ code: "" }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, setFieldValue }) => {
            setSubmitting(true);
            this.verifyCode(values.code, setFieldValue, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Container className="form-fields inner-smartVerify">
                <Row>
                  <Col sm={12}>
                    <h1>
                      {STRINGS[brand][language]?.VERIFY?.HEADER_TITLE ||
                        "Verify"}
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Form.Group controlId="formCode">
                      <Form.Label>
                        {STRINGS[brand][language]?.VERIFY?.OTP_LABEL || "OTP"}
                      </Form.Label>
                      <Form.Control
                        size="lg"
                        type="text"
                        name="code"
                        placeholder={
                          STRINGS[brand][language]?.VERIFY?.OTP_PLACEHOLDER ||
                          "Enter OTP"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.code}
                        className={
                          touched.code && errors.code ? "has-error" : null
                        }
                      />
                      {touched.code && errors.code && (
                        <div className="error-message">{errors.code}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="cta-secondary">
                  <Col sm={12}>
                    <Button variant="link" onClick={() => this.resend()}>
                      {STRINGS[brand][language]?.VERIFY?.RESEND_OTP ||
                        "Resend OTP"}
                    </Button>
                  </Col>
                </Row>
                <Row className="submit-btn-container">
                  <Col md={{ span: 6, offset: 3 }}>
                    <Button
                      type="submit"
                      variant="primary"
                      block
                      size="lg"
                      disabled={isSubmitting}
                    >
                      {STRINGS[brand][language]?.VERIFY?.CONTINUE_CTA ||
                        "Continue"}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      </Layout>
    );
  }
}

export default Verify;
