import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import * as ROUTES from "Constants/Routes";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import VALIDATIONS from "Constants/ValidationList";

import { HTTP_STATUS } from "Communication/Constants";

import checkErrors from "Utils/InputValidator";
import { getSelectedCountry } from "Utils/AboutYouUtilities";

import { getGettingStartedData } from "Redux/Actions/ApplicationAction";
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import DatePicker from "react-datepicker";
import { getCountryList } from "../EmploymentAndIncome/Actions/EmploymentAndIncomeAction";
import {
  doGetProvinceList,
  doAddressKey,
  doGetAddress,
  updateVaultMember,
} from "./Actions/AboutYouActions";
import {
  getCountryCode,
  getIdCountryCode,
  getLicenseCountryCode,
  getLicenseProvinceCode,
  getProduct,
  isScanningAllowed,
} from "../../Utils/CommonUtilities";
import "react-datepicker/dist/react-datepicker.css";
import {
  subYears,
  parse,
  format,
  getYear,
  getMonth,
  sub,
  differenceInCalendarYears,
} from "date-fns";
import { Typeahead } from "react-bootstrap-typeahead";
import PopupModal from "../Common/PopupModal/PopupModal";
import {
  allowedProvince,
  allowKycEdit,
  allowKycEditForJointInSession,
  getFingerPrint,
  getMemberBenefit,
  getNextStepAfterKYC,
  getProductIdByAge,
  isNewMember,
  verifyAge,
} from "../../Utils/LogicUtilities";
import updateApplicantData from "../../Redux/Actions/UpdateApplicantAction";
import {
  storeQuestionnaire,
  storeQuestionnaireProduct,
} from "../Questionnaire/Actions/QuestionnaireAction";
import questionnaire from "../Questionnaire/Questionnaire";
import {
  S_EMAIL,
  S_INSTNT_FINGER_PRINT,
  S_MSISDN,
} from "../../Communication/Constants";
import { setInSessionJointApplicant } from "../JointApplicant/Actions/JointApplicantAction";
import PopupKyc from "./PopupKyc";
import { getProductFromVault } from "../MarketPlace/Action/MarketPlaceAction";

const range = (start, end) => {
  const length = end - start;
  return Array.from({ length }, (_, i) => end - i);
};

const unitTypeList = ["", "Apt", "Suite", "Unit"];
const streetTypeList = [
  "",
  "Ave",
  "Blvd",
  "Bourne",
  "Cres",
  "Dr",
  "Rd",
  "St",
  "Crt",
  "Fwy",
  "Hwy",
  "Pky",
  "Pl",
  "Lane",
  "Way",
];
const directionList = ["", "N", "S", "E", "W", "NE", "NW", "SE", "SW"];

class Review extends Component {
  constructor(props) {
    super(props);
    this.searchField = React.createRef();
    this.searchField2 = React.createRef();
    let startDate = new Date();
    startDate = subYears(startDate, 100);
    let endDate = new Date();
    endDate = subYears(endDate, 12);
    const minDate = sub(new Date(), { years: 100 });
    const maxDate = sub(new Date(), { years: 12 });
    const years = range(getYear(minDate), getYear(maxDate));
    const {
      fName,
      mName,
      lName,
      dOB,
      address,
      addressLine2,
      addressLine3,
      city,
      province,
      postalCode,
      instntTxnId,
    } = this.props;
    this.state = {
      userInfo: null,
      firstName: fName,
      middleName: mName,
      lastName: lName,
      dateOfBirth: dOB,
      address,
      apartment: addressLine2,
      addressLine3,
      city,
      province,
      unitType: "",
      streetType: "",
      direction: "",
      postalCode,
      currentAddressStreetNumber: "",
      currentAddressStreetName: "",
      mailingAddressLine1: "",
      mailingApartment: "",
      mailingCity: "",
      mailingProvince: "",
      mailingPostalCode: "",
      mailingCountry: "",
      mailingUnitType: "",
      mailingStreetType: "",
      mailingDirection: "",
      scanConfirmation: "",
      mailingSameAsCivic: "",
      errors: {
        firstNameErrorList: [],
        lastNameErrorList: [],
        dateOfBirthErrorList: [],
        addressErrorList: [],
        currentAddressStreetNumberErrorList: [],
        currentAddressStreetNameErrorList: [],
        cityErrorList: [],
        provinceErrorList: [],
        postalCodeErrorList: [],
        mailingAddressLine1ErrorList: [],
        mailingStreetNumberErrorList: [],
        mailingStreetNameErrorList: [],
        mailingCityErrorList: [],
        mailingProvinceErrorList: [],
        mailingPostalCodeErrorList: [],
        mailingCountryErrorList: [],
        scanConfirmationErrorList: [],
        mailingSameAsCivicErrorList: [],
      },
      provinceList: [],
      branch: "",
      accountStatementType: "",
      reasonCode: "",
      editMode: true,
      countriesList: [],
      startDate,
      endDate,
      minDate,
      maxDate,
      years,
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      addressInstanceKey: "",
      selectedAddress: "",
      addressSearchResult: [],
      showSearchResult: false,
      blockAddressEdit: true,
      executeAdressSearch: true,
      selectedAddress2: "",
      addressSearchResult2: [],
      showSearchResult2: false,
      blockAddressEdit2: true,
      executeAdressSearch2: true,
      showModal: false,
      modal: {},
      allowPersonalInfoEdit: false,
      allowAddressInfoEdit: true,
      addressValidationMessage: "",
      promoCode: "",
      referralCode: "",
      allowKycEditName: false,
      allowKycEditDob: false,
      doKyc: false,
      kycRequest: null,
      kycSkipRequested: false,
    };
  }

  componentDidMount() {
    const {
      instntTxnId,
      applicationCompleteList,
      finInfo,
      applicantData,
      inSessionJointApplicant,
      sessionCompletedProducts,
      getDataFromServer,
      doSearchMember,
      membershipsData,
    } = this.props;
    console.log("componentDidMount", this.props);
    window.scrollTo(0, 0);
    const { doGetGettingStartedVault } = this.props;
    doGetGettingStartedVault((res) => {
      this.setBranchAndOverderaft(res);
    });

    const { countryList } = this.props;
    if (countryList && countryList.length <= 0) {
      const { doGetCountryList } = this.props;
      doGetCountryList();
    } else {
      this.setState({ countriesList: countryList });
    }

    const { provinceList } = this.props;
    if (provinceList && provinceList.length <= 0) {
      const { doGetProvinceData } = this.props;
      doGetProvinceData();
    } else {
      this.setState({ provinceList });
    }

    getDataFromServer(AppConstants.APPLICATIONSTEP.ABOUTYOU, (response) => {
      console.log("getDataFromServer", response);
      if (response.status === HTTP_STATUS.OK) {
        if (
          finInfo.jointMembership &&
          membershipsData.length === 0 &&
          response.data.acquireType === "search"
        ) {
          doSearchMember(
            {
              cifId: response.data.cifId,
            },
            (res) => {
              console.log(res);
            }
          );
        }
        this.setStateFromProps(response.data);
      }
      if (
        !response.data?.id ||
        (inSessionJointApplicant && !inSessionJointApplicant.applicantId) ||
        allowKycEdit(
          response.data,
          sessionCompletedProducts,
          finInfo,
          inSessionJointApplicant
        )
      ) {
        console.log("allowing edit");
        this.setState({
          allowPersonalInfoEdit: true,
        });
      }

      if (
        finInfo.allowAddressInfoEdit === false &&
        response.data?.id &&
        !inSessionJointApplicant
      ) {
        this.setState({
          allowAddressInfoEdit: false,
        });
      }
      if (allowKycEdit(response.data, sessionCompletedProducts, finInfo)) {
        if (finInfo.kycAllowedEdit.includes(AppConstants.KYC.NAME)) {
          this.setState({ allowKycEditName: true });
        }
        if (finInfo.kycAllowedEdit.includes(AppConstants.KYC.DOB)) {
          this.setState({ allowKycEditDob: true });
        }
      }
    });

    const selectedAddress = [];
    const selectedAddress2 = [];
    if (instntTxnId && instntTxnId.length > 0) {
      const { address, province, postalCode, city } = this.props;
      let instntAddress = address;
      const currentAddress = {
        label: `${address || ""}`,
        AddressLine1: address,
        StateProvince: province,
        PostalCode: postalCode,
        City: city,
        ProcessedBy: "",
        Country: "",
      };
      let apartment = "";
      let currentAddressUnitNumber = "";
      let currentAddressStreetNumber = "";
      let currentAddressStreetName = "";
      const idx = address?.indexOf(" ");
      if (address && idx && idx + 1 < address.length) {
        currentAddressStreetNumber = address.substring(0, idx);
        currentAddressStreetName = address.substring(idx + 1).trim();
        const idx2 = currentAddressStreetNumber.indexOf("-");
        if (
          currentAddressStreetNumber &&
          idx2 &&
          idx2 + 1 < currentAddressStreetNumber.length
        ) {
          currentAddressUnitNumber = currentAddressStreetNumber.substring(
            0,
            idx2
          );
          currentAddressStreetNumber = currentAddressStreetNumber
            .substring(idx2 + 1)
            .trim();
        }
        if (
          finInfo.coreBankingType !== AppConstants.CORE_BANKING_TYPE.OVATION
        ) {
          currentAddress.AddressLine1 = `${currentAddressStreetNumber} ${currentAddressStreetName}`;
          instntAddress = `${currentAddressStreetNumber} ${currentAddressStreetName}`;
          apartment = currentAddressUnitNumber;
        }
      }
      selectedAddress.push({
        ...currentAddress,
        currentAddressUnitNumber,
        currentAddressStreetNumber,
        currentAddressStreetName,
      });
      this.setState({
        selectedAddress,
        selectedAddress2,
        editMode: false,
        allowAddressInfoEdit: false,
        address: instntAddress,
        apartment,
        currentAddressUnitNumber,
        currentAddressStreetNumber,
        currentAddressStreetName,
      });
    }

    const { doAddressKey } = this.props;

    doAddressKey((response) => {
      let addressInstanceKey = "";
      if (response.data.Output && response.data.Output.length > 0) {
        addressInstanceKey = response.data.Output[0].InstanceKey;
      }
      this.setState({ addressInstanceKey });
    });
  }

  componentDidUpdate(prevProps) {
    const {
      provinceList,
      countryList,
      finInfo,
      applicantData,
      inSessionJointApplicant,
      applicationCompleteList,
      instntTxnid,
      sessionCompletedProducts,
    } = this.props;
    const { allowAddressInfoEdit } = this.state;

    if (prevProps.provinceList !== provinceList) {
      this.setProvinceList(provinceList);
    }

    if (prevProps.countryList !== countryList) {
      this.setCountriesList(countryList);
    }
    if (
      finInfo.allowAddressInfoEdit === false &&
      applicantData?.id &&
      !inSessionJointApplicant &&
      allowAddressInfoEdit &&
      applicationCompleteList.length > 0
    ) {
      this.setState({
        allowAddressInfoEdit: false,
      });
    }
    if (
      applicantData !== prevProps.applicantData &&
      allowKycEdit(
        applicantData,
        sessionCompletedProducts,
        finInfo,
        inSessionJointApplicant
      )
    ) {
      if (finInfo.kycAllowedEdit.includes(AppConstants.KYC.NAME)) {
        this.setState({ allowKycEditName: true });
      }
      if (finInfo.kycAllowedEdit.includes(AppConstants.KYC.DOB)) {
        this.setState({ allowKycEditDob: true });
      }
    }
  }

  setStateFromProps = (response) => {
    console.log("setStateFromProps", response);
    const {
      applicationCompleteList,
      finInfo,
      instntTxnId,
      inSessionJointApplicant,
      applicantData,
      vaultMember,
    } = this.props;
    console.log("setStateFromProps", instntTxnId, applicantData.id);
    const selectedAddress = [];
    const selectedAddress2 = [];
    if (applicantData.id) {
      const { member, address } = response;
      // default core banking
      let currentAddress = address.currentAddressLine1;
      let mailingAddress = address.mailingAddressLine1;
      let city = address.currentAddressCity;
      let province = address.currentAddressProvince;
      let { mailingCity } = address;
      let { mailingProvince } = address;
      let apartment = address.currentAddressUnitNumber;
      let mailingApartment = address.mailingUnitNumber;
      console.log(
        "coreBankingType",
        finInfo.coreBankingType,
        address.currentAddressLine2,
        address.currentAddressLine3
      );
      if (finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.RFS) {
        const currentAddressArray = currentAddress.split("-");
        if (currentAddressArray.length > 1) {
          apartment = currentAddressArray[0].trim();
          currentAddress = currentAddressArray[1].trim();
        }
        const mailingAddressArray = mailingAddress.split("-");
        if (mailingAddressArray.length > 1) {
          mailingApartment = mailingAddressArray[0].trim();
          mailingAddress = mailingAddressArray[1].trim();
        }
        if (!city && address.currentAddressLine2) {
          city = address.currentAddressLine2.replace(` ${province}`, "");
        }
        if (!mailingCity && address.mailingAddressLine2) {
          city = address.mailingAddressLine2.replace(` ${mailingProvince}`, "");
        }
      } else if (
        finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.DNA
      ) {
        city = address.currentAddressLine3;
        if (address.currentAddressLine1.indexOf(" - ") > 0) {
          const addr = address.currentAddressLine1.split(" - ");
          apartment = addr[0];
          currentAddress = addr[1];
        }
        if (address.mailingAddressLine1.indexOf(" - ") > 0) {
          const addr = address.mailingAddressLine1.split(" - ");
          mailingApartment = addr[0];
          mailingAddress = addr[1];
        }
      }

      if (finInfo.cityReformat) {
        const cityAndProvince = address.currentAddressLine3;
        console.log("cityAndProvince", cityAndProvince);
        if (cityAndProvince && cityAndProvince.indexOf(" ") > 1) {
          city = cityAndProvince
            .substring(0, cityAndProvince.lastIndexOf(" "))
            .trim();
          province = cityAndProvince
            .substring(cityAndProvince.lastIndexOf(" "))
            .trim();
        }
        const mailingCityAndProvince = address.mailingAddressLineType3;
        if (mailingCityAndProvince && mailingCityAndProvince.indexOf(" ") > 1) {
          mailingCity = mailingCityAndProvince
            .substring(0, mailingCityAndProvince.lastIndexOf(" "))
            .trim();
          mailingProvince = mailingCityAndProvince
            .substring(mailingCityAndProvince.lastIndexOf(" "))
            .trim();
        }
      }

      selectedAddress.push({
        label: currentAddress,
        AddressLine1: currentAddress,
        StateProvince: province,
        PostalCode: address.currentAddressPostalCode,
        City: city,
        ProcessedBy: "",
        Country: "",
      });
      selectedAddress2.push({
        label:
          finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.OVATION
            ? ""
            : mailingAddress,
        AddressLine1: mailingAddress,
        StateProvince: mailingProvince,
        PostalCode: address.mailingPostalCode,
        City: mailingCity,
        ProcessedBy: "",
        Country: address.mailingCountry,
      });
      let allowEdit = true;
      let allowAddressInfoEdit = !!finInfo.allowAddressInfoEdit;
      if (applicationCompleteList && applicationCompleteList.length > 0) {
        allowEdit = false;
        allowAddressInfoEdit = true;
      } else if (allowAddressInfoEdit === false) {
        allowAddressInfoEdit = true;
      }
      if (inSessionJointApplicant) {
        allowEdit = true;
        allowAddressInfoEdit = true;
      } else if (applicantData?.acquireType === "search") {
        allowEdit = false;
      }
      this.setState({
        firstName: inSessionJointApplicant
          ? inSessionJointApplicant.vault?.name || member.name
          : vaultMember?.name || member.name,
        middleName: inSessionJointApplicant
          ? inSessionJointApplicant.vault?.middleName || member.middleName
          : vaultMember?.middleName || member.middleName,
        lastName: inSessionJointApplicant
          ? inSessionJointApplicant.vault?.surname || member.surname
          : vaultMember?.surname || member.surname,
        dateOfBirth: inSessionJointApplicant
          ? inSessionJointApplicant.vault?.dob || member.dob
          : vaultMember?.dob || member.dob,
        address: currentAddress,
        apartment,
        city,
        province,
        postalCode: address.currentAddressPostalCode,
        unitType: address.currentAddressUnitType,
        streetType: address.currentAddressStreetType,
        direction: address.currentAddressStreetDir,
        currentAddressStreetNumber: address.currentAddressStreetNumber,
        currentAddressStreetName: address.currentAddressStreetName,
        mailingAddressLine1: mailingAddress,
        mailingApartment,
        mailingCity,
        mailingProvince,
        mailingPostalCode: address.mailingPostalCode,
        mailingCountry: address.mailingCountry,
        mailingUnitType: address.mailingUnitType,
        mailingStreetType: address.mailingStreetType,
        mailingStreetDir: address.mailingStreetDir,
        mailingStreetNumber: address.mailingStreetNumber,
        mailingStreetName: address.mailingStreetName,
        mailingSameAsCivic: address.mailingSameAsCivic,
        editMode: allowEdit,
        allowAddressInfoEdit,
        selectedAddress,
        selectedAddress2,
        blockAddressEdit: !address.currentAddressLine1,
        blockAddressEdit2: !address.mailingAddressLine1,
      });
    }
  };

  setCountriesList(countriesList) {
    this.setState({ countriesList });
  }

  setBranchAndOverderaft(gettingStarted) {
    console.log("setBranchAndOverderaft", gettingStarted);
    const {
      getActiveProduct,
      vaultProductList,
      applicantData,
      finInfo,
    } = this.props;
    const activeProduct = getActiveProduct(vaultProductList);
    if (activeProduct.prospect?.id) {
      console.log("testing", applicantData);
      const promoCode =
        applicantData.regulatory.regulatoryFields?.find(
          (e) => e.Text === STRINGS.GETTING_STARTED.PROMO_CODE
        )?.Value || "";
      const referralCode =
        applicantData.regulatory.regulatoryFields?.find(
          (e) => e.Text === STRINGS.GETTING_STARTED.REFERRAL_CODE
        )?.Value || "";
      console.log("promo", promoCode, referralCode);
      let { accountStatementType } = gettingStarted;
      if (activeProduct.prospect.contact.mailingPreferenceStatement) {
        if (
          finInfo.mailingPreference.paper ===
          activeProduct.prospect.contact.mailingPreferenceStatement
        ) {
          accountStatementType = AppConstants.GETTINGSTARTED.PAPER;
        } else {
          accountStatementType = AppConstants.GETTINGSTARTED.EMAIL;
        }
      }
      this.setState({
        branch: activeProduct.prospect.member.branch || gettingStarted.branch,
        accountStatementType,
        reasonCode:
          activeProduct.prospect.member.reasonOpened ||
          gettingStarted.reasonCode,
        promoCode,
        referralCode,
      });
    } else {
      this.setState({
        branch: gettingStarted.branch,
        accountStatementType: gettingStarted.accountStatementType,
        reasonCode:
          gettingStarted.reasonCode || applicantData?.member?.reasonOpened,
        promoCode: gettingStarted.promoCode,
        referralCode: gettingStarted.referralCode,
      });
    }
  }

  setProvinceList = (provinceList) => {
    this.setState({
      provinceList,
    });
  };

  setDOB = (dob) => {
    const { errors } = this.state;
    this.setState({
      dateOfBirth: format(dob, "yyyy-MM-dd"),
      errors: {
        ...errors,
        dateOfBirthErrorList: [],
      },
    });
  };

  onChangeHandler = (event) => {
    const { errors, editMode, allowKycEditName, allowKycEditDob } = this.state;
    const inputField = event?.target.name;
    console.log("inputField", inputField);
    let eventValue = event?.target.value;

    const index = AppConstants.ABOUT_YOU.EDIT_MODE_INPUTLIST.findIndex(
      (elem) => inputField === elem
    );

    // Replace invalid characters for the specific fields
    if (
      eventValue &&
      (inputField === "firstName" ||
        inputField === "middleName" ||
        inputField === "lastName" ||
        inputField === "city" ||
        inputField === "mailingCity" ||
        inputField === "mailingStreetName" ||
        inputField === "currentAddressStreetName")
    ) {
      eventValue = eventValue.replace(
        /[^A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð\. \-']/gi,
        ""
      );

      // Capitalize the first letter of each word, while preserving user-entered capital letters
      eventValue = eventValue
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter only
        .join(" ");
    }

    if (editMode || index === -1 || allowKycEditName || allowKycEditDob) {
      this.setState(
        {
          [inputField]: eventValue,
          errors: {
            ...errors,
            [`${event?.target.name}ErrorList`]: [],
          },
        },
        () => {
          if (
            inputField === STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.FIRSTNAMEREF
          ) {
            this.textInput.focus();
          }
        }
      );
    }
  };

  verifyAddressLength = () => {
    let valid = true;
    const {
      address,
      apartment,
      mailingAddressLine1,
      mailingApartment,
      addressValidationMessage,
      mailingSameAsCivic,
    } = this.state;
    const { finInfo } = this.props;
    console.log(
      "verifyAddressLength ",
      addressValidationMessage,
      mailingSameAsCivic
    );

    const addressLimit = finInfo.addressLimit
      ? finInfo.addressLimit - 3
      : AppConstants.ABOUT_YOU.ADDRESS_LIMIT - 3;

    const addressLength = address?.length + apartment?.length;
    console.log("addressLength ", addressLength, addressLimit);
    if (addressLength > addressLimit) {
      valid = false;
      console.log("invalid address and apartment");
      this.setState({
        showAddressMessage: true,
        modal: {
          title: STRINGS.ABOUTYOU.ADDRESS_LIMIT.TITLE,
          description: `${STRINGS.ABOUTYOU.ADDRESS_LIMIT.ADDRESS_MESSAGE} ${addressLimit} ${STRINGS.ABOUTYOU.ADDRESS_LIMIT.ADDRESS_MESSAGE1}`,
        },
      });
    } else if (!mailingSameAsCivic) {
      const mailingLength =
        mailingAddressLine1?.length + mailingApartment?.length;
      console.log("mailingAddressLength ", mailingLength, addressLimit);
      if (mailingLength > addressLimit) {
        valid = false;
        console.log("invalid mailing address and apartment");
        this.setState({
          showAddressMessage: true,
          modal: {
            title: STRINGS.ABOUTYOU.ADDRESS_LIMIT.TITLE,
            description: `${STRINGS.ABOUTYOU.ADDRESS_LIMIT.MAILING_MESSAGE} ${addressLimit} ${STRINGS.ABOUTYOU.ADDRESS_LIMIT.MAILING_MESSAGE1}`,
          },
        });
      }
    }

    return valid;
  };

  submitData = () => {
    const { doCreateNewApplicant } = this.props;
    const { dateOfBirth, allowKycEditName, allowKycEditDob } = this.state;
    const {
      vaultProductList,
      getActiveProduct,
      doUpdateApplicant,
      instntTxnId,
      isDesktop,
      documentNumber,
      expirationDate,
      issueDate,
      addressState,
      inSessionJointApplicant,
      finInfo,
      fingerprint,
      idTypes,
      idName,
      applicantData,
      issuerCode,
      idType,
      sessionCompletedProducts,
      doUpdateVaultMember,
      vaultMember,
      doSetInSessionJointApplicant,
    } = this.props;
    if (this.verifyData()) {
      if (verifyAge(dateOfBirth, getActiveProduct(vaultProductList))) {
        if (
          isNewMember(applicantData) &&
          !allowedProvince(this.state.province, finInfo)
        ) {
          this.setState({
            showModal: true,
            modal: {
              title: STRINGS.ABOUTYOU.PROVINCE_RESTRICTION.TITLE,
              description: STRINGS.ABOUTYOU.PROVINCE_RESTRICTION.MESSAGE.split(
                AppConstants.TEMPLATE.PROVINCE
              ).join(finInfo.allowedProvinceForNewMember),
            },
          });
        } else if (this.verifyAddressLength()) {
          const {
            firstName,
            lastName,
            middleName,
            address,
            apartment,
            unitType,
            streetType,
            direction,
            city,
            province,
            postalCode,
            currentAddressStreetNumber,
            currentAddressStreetName,
            mailingAddressLine1,
            mailingApartment,
            mailingCity,
            mailingProvince,
            mailingPostalCode,
            mailingCountry,
            mailingSameAsCivic,
            branch,
            reasonCode,
            accountStatementType,
            mailingUnitType,
            mailingStreetType,
            mailingStreetDir,
            mailingStreetNumber,
            mailingStreetName,
            promoCode,
            referralCode,
            allowKycEditName,
            allowKycEditDob,
          } = this.state;
          console.log(
            "precheck vault member",
            !isNewMember(applicantData),
            allowKycEditName,
            allowKycEditDob
          );
          if (
            !isNewMember(applicantData) &&
            !inSessionJointApplicant &&
            (allowKycEditName || allowKycEditDob)
          ) {
            console.log("update vault member");
            doUpdateVaultMember(
              {
                ...vaultMember,
                name: firstName,
                middleName,
                surname: lastName,
                dob: dateOfBirth,
              },
              (res) => console.log(res)
            );
          } else if (
            !isNewMember(applicantData) &&
            inSessionJointApplicant &&
            inSessionJointApplicant?.applicantId &&
            (allowKycEditName || allowKycEditDob)
          ) {
            doUpdateVaultMember(
              {
                ...vaultMember,
                name: firstName,
                middleName,
                surname: lastName,
                dob: dateOfBirth,
                inSessionJoint: inSessionJointApplicant.applicantId,
              },
              (res) => console.log(res)
            );
          }
          let request = {};
          const activeProduct = getActiveProduct(vaultProductList);

          let bundleProductId = activeProduct.productId;
          if (
            activeProduct.productList &&
            activeProduct.productList.length > 0
          ) {
            bundleProductId = getProductIdByAge(
              dateOfBirth,
              activeProduct.productList
            );
          }
          console.log("bundleProductId", bundleProductId);
          if (
            (activeProduct?.applicationId &&
              !inSessionJointApplicant &&
              !activeProduct.isJointProduct) ||
            (activeProduct.isJointProduct && activeProduct?.applicantId) ||
            (inSessionJointApplicant && inSessionJointApplicant?.applicantId)
          ) {
            request = {
              member: {
                name: firstName,
                middleName,
                surname: lastName,
                dob: dateOfBirth,
                branch,
                reasonOpened: reasonCode,
              },
              address: {
                currentAddressLine1: address,
                currentAddressLine3: "",
                currentAddressCity: city,
                currentAddressProvince: province,
                currentAddressPostalCode: postalCode,
                currentAddressStreetDir: direction,
                currentAddressStreetType: streetType,
                currentAddressUnitNumber: apartment,
                currentAddressUnitType: unitType,
                currentAddressStreetNumber,
                currentAddressStreetName,
                mailingSameAsCivic,
              },
              regulatory: {
                mailStmt:
                  accountStatementType === AppConstants.GETTINGSTARTED.PAPER,
                eStmt:
                  accountStatementType === AppConstants.GETTINGSTARTED.EMAIL,
              },
              contact: {
                mailingPreferenceStatement:
                  finInfo.mailingPreference[accountStatementType],
              },
              creditBanking: {
                creditCheckConsentType: finInfo.creditCheckConsentType,
              },
              bundleProductId,
            };

            if (
              inSessionJointApplicant &&
              !inSessionJointApplicant.kycAlreadyDone &&
              allowKycEditForJointInSession(applicantData, finInfo)
            ) {
              request.doVerification = true;
              request.instntTxnId = instntTxnId;
              request.member.email = inSessionJointApplicant.inviteDetail.email;
              request.address.currentAddressCellPhoneArea = inSessionJointApplicant.inviteDetail.msisdn
                .substring(1)
                .substring(0, 3);
              request.address.currentAddressCellPhone = inSessionJointApplicant.inviteDetail.msisdn
                .substring(1)
                .substring(3);
            } else if (
              allowKycEdit(
                applicantData,
                sessionCompletedProducts,
                finInfo,
                inSessionJointApplicant
              ) &&
              activeProduct.isJointProduct &&
              !activeProduct.kycAlreadyDone
            ) {
              request.doVerification = true;
              request.instntTxnId = instntTxnId;
              request.fingerprint = fingerprint || getFingerPrint();
            }

            if (activeProduct.isJointProduct) {
              request.contact.mailingPreferenceStatement =
                activeProduct.mailingPreferenceStatement;
              request.member.reasonOpened = activeProduct.reasonOpened;
            }

            if (
              finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.RFS
            ) {
              if (apartment && apartment.trim() !== "") {
                request.address.currentAddressLine1 = `${apartment} - ${address}`;
              }
              if (finInfo.cityReformatRfs) {
                request.address.currentAddressLine2 = `${city} ${province}`;
              }
              request.address.currentAddressStreetName = "";
              request.address.currentAddressStreetNumber = "";
            } else if (
              finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.DNA
            ) {
              if (apartment && apartment.trim() !== "") {
                request.address.currentAddressLine1 = `${apartment} - ${address}`;
              } else {
                request.address.currentAddressLine1 = address;
              }
              request.address.currentAddressUnitNumber = "";
              request.address.currentAddressLine3 = city;
              request.address.currentAddressStreetName = "";
              request.address.currentAddressStreetNumber = "";
            }
            if (finInfo.cityReformat) {
              request.address.currentAddressLine3 = `${city} ${province}`;
            }
            if (mailingSameAsCivic) {
              request = {
                ...request,
                address: {
                  ...request.address,
                  mailingAddressLineType1: "",
                  mailingAddressLine1: "",
                  mailingAddressLine2: "",
                  mailingAddressLine3: "",
                  mailingCity: "",
                  mailingProvince: "",
                  mailingPostalCode: "",
                  mailingCountry: "",
                  mailingUnitNumber: "",
                  mailingUnitType: "",
                  mailingStreetType: "",
                  mailingStreetDir: "",
                  mailingStreetNumber: "",
                  mailingStreetName: "",
                },
              };
            } else {
              request = {
                ...request,
                address: {
                  ...request.address,
                  mailingAddressLineType1: "",
                  mailingAddressLine1,
                  mailingCity,
                  mailingProvince,
                  mailingPostalCode,
                  mailingCountry,
                  mailingUnitNumber: mailingApartment,
                  mailingUnitType,
                  mailingStreetType,
                  mailingStreetDir,
                  mailingStreetNumber,
                  mailingStreetName,
                },
              };
              if (
                finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.RFS
              ) {
                if (mailingApartment && mailingApartment.trim() !== "") {
                  request.address.mailingAddressLine1 = `${mailingApartment} - ${mailingAddressLine1}`;
                }
                if (finInfo.cityReformatRfs) {
                  request.address.mailingAddressLine2 = `${mailingCity} ${mailingProvince}`;
                }
                request.address.mailingStreetNumber = "";
                request.address.mailingStreetName = "";
              } else if (
                finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.DNA
              ) {
                if (mailingApartment && mailingApartment.trim() !== "") {
                  request.address.mailingAddressLine1 = `${mailingApartment} - ${mailingAddressLine1}`;
                }
                request.address.mailingUnitNumber = "";
                request.address.mailingAddressLine3 = mailingCity;
                request.address.mailingStreetNumber = "";
                request.address.mailingStreetName = "";
              }
              if (finInfo.cityReformat) {
                request.address.mailingAddressLine3 = `${mailingCity} ${mailingProvince}`;
              }
            }
          } else {
            console.log("doc date", expirationDate);
            let expirationDateParsed = null;
            if (expirationDate && expirationDate !== "") {
              try {
                expirationDateParsed = parse(
                  expirationDate,
                  "dd/MMM/yyyy",
                  new Date()
                );
                expirationDateParsed = format(
                  expirationDateParsed,
                  "yyyy-MM-dd"
                );
              } catch (e) {
                expirationDateParsed = "";
                console.log(e);
              }
              console.log("doc date", expirationDateParsed);
            }
            let issueDateParsed = null;
            if (issueDate && issueDate !== "") {
              try {
                issueDateParsed = parse(issueDate, "dd/MMM/yyyy", new Date());
                issueDateParsed = format(issueDateParsed, "yyyy-MM-dd");
              } catch (e) {
                issueDateParsed = "";
                console.log(e);
              }
            }
            request = {
              firstName,
              middleName,
              surname: lastName,
              dateOfBirth,
              address,
              city,
              state: province,
              postalCode,
              currentAddressUnitType: unitType,
              currentAddressUnitNumber: apartment,
              currentAddressStreetType: streetType,
              currentAddressStreetDir: direction,
              currentAddressStreetNumber,
              currentAddressStreetName,
              branch,
              reasonOpened: reasonCode,
              fingerprint: fingerprint || getFingerPrint(),
              instntTxnId: instntTxnId,
              mailingSameAsCivic,
              bundleProductId,
              benefit: getMemberBenefit(
                dateOfBirth,
                finInfo.memberBenefit,
                activeProduct.benefit
              ),
              rep: finInfo.rep,
              idType1: isDesktop
                ? finInfo.idType1
                : idTypes?.find((item) => item.description === idName)?.code,
              idDetail1: isDesktop ? finInfo.idDetail1 : documentNumber,
              country: finInfo.verificationCountry,
              expiryDate1: isDesktop
                ? finInfo.expiryDate1
                : expirationDateParsed,
              issueDate1: issueDateParsed,
              citizenship: finInfo.citizenship,
              fintracRiskLevel: finInfo.fintracRiskLevel,
              mailStmt:
                accountStatementType === AppConstants.GETTINGSTARTED.PAPER,
              eStmt: accountStatementType === AppConstants.GETTINGSTARTED.EMAIL,
              mailingPreferenceStatement:
                finInfo.mailingPreference[accountStatementType],
              creditCheckConsentType: finInfo.creditCheckConsentType,
              useAddressCellPhoneOnly: finInfo.useAddressCellPhoneOnly,
              promoCode,
              referralCode,
              idProvince1: isDesktop
                ? finInfo.idProvince1
                : idType === AppConstants.IDTypes.LICENSE.desc
                ? getLicenseProvinceCode(finInfo, idName, issuerCode)
                : "",
              placeOfIssuance1: isDesktop
                ? finInfo.idProvince1
                : idType === AppConstants.IDTypes.LICENSE.desc
                ? getLicenseProvinceCode(finInfo, idName, issuerCode)
                : "",
              idCountry1: isDesktop
                ? finInfo.idCountry1
                : idType === AppConstants.IDTypes.LICENSE.desc
                ? getLicenseCountryCode(finInfo, idName, issuerCode)
                : getIdCountryCode(finInfo, idName, issuerCode),
              idCountryOfIssuance1: isDesktop
                ? finInfo.idCountry1
                : idType === AppConstants.IDTypes.LICENSE.desc
                ? getLicenseCountryCode(finInfo, idName, issuerCode)
                : getIdCountryCode(finInfo, idName, issuerCode),
              isDesktop,
              idType,
              idName,
            };

            if (
              allowKycEdit(
                applicantData,
                sessionCompletedProducts,
                finInfo,
                inSessionJointApplicant
              )
            ) {
              request.doVerification = true;
              request.email = sessionStorage.getItem(S_EMAIL);
              request.msisdn = sessionStorage.getItem(S_MSISDN);
            }

            if (!applicantData?.id) {
              request.message = STRINGS.ABOUTYOU.PARTFIVE.PERSONALINFO_SPINNER;
            }
            if (activeProduct.isJointProduct) {
              request.mailingPreferenceStatement =
                activeProduct.mailingPreferenceStatement;
              request.reasonOpened = activeProduct.reasonOpened;
            }

            if (
              finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.RFS
            ) {
              if (apartment && apartment.trim() !== "") {
                request.address = `${apartment} - ${address}`;
              }
              if (finInfo.cityReformatRfs) {
                request.addressLine2 = `${city} ${province}`;
              }
              request.currentAddressStreetName = "";
              request.currentAddressStreetNumber = "";
            } else if (
              finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.DNA
            ) {
              if (apartment && apartment.trim() !== "") {
                request.address = `${apartment} - ${address}`;
              }
              request.addressLine3 = city;
              request.currentAddressStreetName = "";
              request.currentAddressStreetNumber = "";
            }

            if (finInfo.cityReformat) {
              request.addressLine3 = `${city} ${province}`;
            }

            if (activeProduct.isJointProduct) {
              request.branch = activeProduct.branch;
            }

            if (!isDesktop) {
              request = { ...request, instntTxnId };
            }

            if (mailingSameAsCivic) {
              request = {
                ...request,
                mailingAddressLineType1: "",
                mailingAddressLine1: "",
                mailingAddressLine2: "",
                mailingAddressLine3: "",
                mailingCity: "",
                mailingProvince: "",
                mailingPostalCode: "",
                mailingCountry: "",
                mailingUnitNumber: "",
                mailingUnitType: "",
                mailingStreetType: "",
                mailingStreetDir: "",
                mailingStreetNumber: "",
                mailingStreetName: "",
              };
            } else {
              request = {
                ...request,
                mailingAddressLineType1: "",
                mailingAddressLine1,
                mailingCity,
                mailingProvince,
                mailingPostalCode,
                mailingCountry,
                mailingUnitNumber: mailingApartment,
                mailingUnitType,
                mailingStreetType,
                mailingStreetDir,
                mailingStreetNumber,
                mailingStreetName,
              };
              if (
                finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.RFS
              ) {
                if (mailingApartment && mailingApartment.trim() !== "") {
                  request.mailingAddressLine1 = `${mailingApartment} - ${mailingAddressLine1}`;
                }
                if (finInfo.cityReformatRfs) {
                  request.mailingAddressLine2 = `${mailingCity} ${mailingProvince}`;
                }
                request.mailingStreetNumber = "";
                request.mailingStreetName = "";
              } else if (
                finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.DNA
              ) {
                if (mailingApartment && mailingApartment.trim() !== "") {
                  request.mailingAddressLine1 = `${mailingApartment} - ${mailingAddressLine1}`;
                }
                request.mailingUnitNumber = "";
                request.mailingAddressLine3 = mailingCity;
                request.mailingStreetNumber = "";
                request.mailingStreetName = "";
              }

              if (finInfo.cityReformat) {
                request.mailingAddressLine3 = `${mailingCity} ${mailingProvince}`;
              }
            }
            if (!inSessionJointApplicant && activeProduct?.applicantId) {
              doUpdateApplicant(
                {
                  member: {
                    name: firstName,
                    middleName,
                    surname: lastName,
                    dob: dateOfBirth,
                  },
                  address: {
                    currentAddressLine1: request.address,
                    currentAddressLine2: request.addressLine2,
                    currentAddressLine3: request.addressLine3,
                    currentAddressCity: city,
                    currentAddressProvince: province,
                    currentAddressPostalCode: postalCode,
                    currentAddressUnitNumber: request.currentAddressUnitNumber,
                    currentAddressUnitType: request.currentAddressUnitType,
                    currentAddressStreetType: request.currentAddressStreetType,
                    currentAddressStreetDir: request.currentAddressStreetDir,
                    currentAddressStreetNumber,
                    currentAddressStreetName,
                    mailingSameAsCivic,
                    mailingAddressLine1: request.mailingAddressLine1,
                    mailingAddressLine2: request.mailingAddressLine2,
                    mailingAddressLine3: request.mailingAddressLine3,
                    mailingCity: request.mailingCity,
                    mailingProvince: request.mailingProvince,
                    mailingPostalCode: request.mailingPostalCode,
                    mailingCountry: request.mailingCountry,
                    mailingUnitNumber: request.mailingUnitNumber,
                    mailingUnitType,
                    mailingStreetType,
                    mailingStreetDir,
                    mailingStreetNumber,
                    mailingStreetName,
                  },
                },
                activeProduct?.applicantId
              );
            }
          }
          const { continuehandler, doUpdateProductToVault } = this.props;
          if (inSessionJointApplicant) {
            if (
              finInfo.qr &&
              !isScanningAllowed() &&
              !inSessionJointApplicant?.applicantId
            ) {
              request.kycOnly = true;
              request.email = inSessionJointApplicant.inviteDetail.email;
              request.msisdn = inSessionJointApplicant.inviteDetail.msisdn;
              doCreateNewApplicant(request, (response) => {
                if (response.status === HTTP_STATUS.OK) {
                  if (response.data.kycResult === "Approved") {
                    delete request.kycOnly;
                    delete request.email;
                    delete request.msisdn;
                    inSessionJointApplicant.kycAlreadyDone = true;
                    inSessionJointApplicant.instntTxnId = request.instntTxnId;
                    doSetInSessionJointApplicant(
                      inSessionJointApplicant,
                      () => {}
                    );
                    continuehandler(
                      request,
                      AppConstants.APPLICATIONSTEP.ABOUTYOU
                    );
                  } else {
                    delete vaultProductList[0].kycStatus;
                    delete vaultProductList[0].kycInsntTxnId;
                    doUpdateProductToVault(vaultProductList, () => {
                      this.setState({ kycSkipRequested: false });
                      this.processMobileKyc(inSessionJointApplicant, request);
                    });
                  }
                }
              });
            } else {
              inSessionJointApplicant.kycAlreadyDone = true;
              inSessionJointApplicant.instntTxnId = request.instntTxnId;
              doSetInSessionJointApplicant(inSessionJointApplicant, () => {});
              continuehandler(request, AppConstants.APPLICATIONSTEP.ABOUTYOU);
            }
          } else {
            if (finInfo.qr && !activeProduct?.applicantId) {
              if (isScanningAllowed()) {
                continuehandler(request, AppConstants.APPLICATIONSTEP.ABOUTYOU);
              } else {
                request.kycOnly = true;
                request.email = sessionStorage.getItem(S_EMAIL);
                request.msisdn = sessionStorage.getItem(S_MSISDN);
                doCreateNewApplicant(request, (response) => {
                  if (response.status === HTTP_STATUS.OK) {
                    if (response.data.kycResult === "Approved") {
                      delete request.kycOnly;
                      delete request.email;
                      delete request.msisdn;
                      continuehandler(
                        request,
                        AppConstants.APPLICATIONSTEP.ABOUTYOU
                      );
                    } else {
                      delete vaultProductList[0].kycStatus;
                      delete vaultProductList[0].kycInsntTxnId;
                      doUpdateProductToVault(vaultProductList, () => {
                        this.setState({ kycSkipRequested: false });
                        this.processMobileKyc(null, request);
                      });
                    }
                  }
                });
              }
            } else {
              if (
                request.doVerification &&
                !vaultProductList[0].kycAlreadyDone
              ) {
                vaultProductList[0].kycAlreadyDone = true;
                doUpdateProductToVault(vaultProductList, () => {
                  continuehandler(
                    request,
                    AppConstants.APPLICATIONSTEP.ABOUTYOU
                  );
                });
              } else {
                continuehandler(request, AppConstants.APPLICATIONSTEP.ABOUTYOU);
              }
            }
          }
        }
      } else {
        console.log("age restriction");
        this.setState({
          showModal: true,
          modal: {
            title: STRINGS.ABOUTYOU.AGE_RESTRICTION.TITLE,
            description: STRINGS.ABOUTYOU.AGE_RESTRICTION.MESSAGE,
            ageRestriction: !!this.getCurrentActiveProductGroup()
              ?.questionnaire,
          },
        });
      }
    }
  };

  processMobileKyc = (inSessionJointApplicant, request) => {
    const {
      getActiveProduct,
      doGetProductFromVault,
      doUpdateProductToVault,
    } = this.props;
    const { doKyc, kycRequest, kycSkipRequested } = this.state;
    console.log(
      "processMobileKyc",
      inSessionJointApplicant,
      request,
      kycSkipRequested
    );
    if (kycSkipRequested) {
      return;
    }
    if (!kycRequest) {
      this.setState({ kycRequest: request });
    }
    doGetProductFromVault((vaultProductList) => {
      console.log("vaultProductList", vaultProductList);
      if (vaultProductList?.status === HTTP_STATUS.OK) {
        const activeProduct = getActiveProduct(vaultProductList.data);
        console.log("activeProduct", activeProduct);
        if (!activeProduct.kycStatus || activeProduct.kycStatus === "started") {
          if (inSessionJointApplicant) {
            vaultProductList.data[0].inSessionJointApplicant = inSessionJointApplicant;
          }
          vaultProductList.data[0].kycStatus = "started";
          console.log("new vaultProductList", vaultProductList.data);
          doUpdateProductToVault(vaultProductList.data);
          console.log("doKyc");
          this.setState({
            doKyc: true,
          });
          setTimeout(
            this.processMobileKyc,
            10000,
            inSessionJointApplicant,
            request
          );
        } else if (
          !doKyc ||
          activeProduct?.kycStatus === "end" ||
          activeProduct?.kycStatus === "mobileEnd"
        ) {
          // do nothing (end loop)
        } else {
          setTimeout(
            this.processMobileKyc,
            10000,
            inSessionJointApplicant,
            request
          );
        }
      }
    });
  };

  verifyData = () => {
    const {
      firstName,
      lastName,
      dateOfBirth,
      address,
      city,
      province,
      postalCode,
      currentAddressStreetNumber,
      currentAddressStreetName,
      mailingAddressLine1,
      mailingCity,
      mailingProvince,
      mailingPostalCode,
      mailingCountry,
      mailingStreetNumber,
      mailingStreetName,
      errors,
      scanConfirmation,
      mailingSameAsCivic,
    } = this.state;
    console.log("verifyData", this.state);
    const { isDesktop, finInfo } = this.props;
    let errorList = { ...errors };
    let valid = true;

    const firstNameErrorList = checkErrors(
      firstName,
      VALIDATIONS.ABOUTYOU.FIRSTNAME
    );
    errorList = { ...errorList, firstNameErrorList };
    if (firstNameErrorList && firstNameErrorList.length > 0) {
      valid = false;
    }

    const lastNameErrorList = checkErrors(
      lastName,
      VALIDATIONS.ABOUTYOU.LASTNAME
    );
    errorList = { ...errorList, lastNameErrorList };
    if (lastNameErrorList && lastNameErrorList.length > 0) {
      valid = false;
    }

    const dateOfBirthErrorList = checkErrors(
      dateOfBirth,
      VALIDATIONS.ABOUTYOU.DOB
    );
    errorList = { ...errorList, dateOfBirthErrorList };
    if (dateOfBirthErrorList && dateOfBirthErrorList.length > 0) {
      valid = false;
    }

    if (finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.OVATION) {
      const currentAddressStreetNumberErrorList = checkErrors(
        currentAddressStreetNumber,
        VALIDATIONS.ABOUTYOU.STREET_NO
      );
      errorList = { ...errorList, currentAddressStreetNumberErrorList };
      if (
        currentAddressStreetNumberErrorList &&
        currentAddressStreetNumberErrorList.length > 0
      ) {
        valid = false;
      }
      const currentAddressStreetNameErrorList = checkErrors(
        currentAddressStreetName,
        VALIDATIONS.ABOUTYOU.STREET_NAME
      );
      errorList = { ...errorList, currentAddressStreetNameErrorList };
      if (
        currentAddressStreetNumberErrorList &&
        currentAddressStreetNameErrorList.length > 0
      ) {
        valid = false;
      }
    } else {
      const addressErrorList = checkErrors(
        address,
        VALIDATIONS.ABOUTYOU.ADDRESS
      );
      console.log("addressErrorList", addressErrorList);
      errorList = { ...errorList, addressErrorList };
      if (addressErrorList && addressErrorList.length > 0) {
        valid = false;
      }
    }

    const cityErrorList = checkErrors(city, VALIDATIONS.ABOUTYOU.CITY);
    errorList = { ...errorList, cityErrorList };
    if (cityErrorList && cityErrorList.length > 0) {
      valid = false;
    }

    const provinceErrorList = checkErrors(
      province,
      VALIDATIONS.ABOUTYOU.PROVINCE
    );
    errorList = { ...errorList, provinceErrorList };
    if (provinceErrorList && provinceErrorList.length > 0) {
      valid = false;
    }

    const postalCodeErrorList = checkErrors(
      postalCode,
      VALIDATIONS.ABOUTYOU.CA_POSTALCODE
    );
    errorList = { ...errorList, postalCodeErrorList };
    if (postalCodeErrorList && postalCodeErrorList.length > 0) {
      valid = false;
    }

    if (!mailingSameAsCivic) {
      if (finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.OVATION) {
        const mailingStreetNumberErrorList = checkErrors(
          mailingStreetNumber,
          VALIDATIONS.ABOUTYOU.STREET_NO
        );
        errorList = { ...errorList, mailingStreetNumberErrorList };
        if (
          mailingStreetNumberErrorList &&
          mailingStreetNumberErrorList.length > 0
        ) {
          valid = false;
        }

        const mailingStreetNameErrorList = checkErrors(
          mailingStreetName,
          VALIDATIONS.ABOUTYOU.STREET_NAME
        );
        errorList = { ...errorList, mailingStreetNameErrorList };
        if (
          mailingStreetNameErrorList &&
          mailingStreetNameErrorList.length > 0
        ) {
          valid = false;
        }
      } else {
        const mailingAddressLine1ErrorList = checkErrors(
          mailingAddressLine1,
          VALIDATIONS.ABOUTYOU.ADDRESS
        );
        errorList = { ...errorList, mailingAddressLine1ErrorList };
        if (
          mailingAddressLine1ErrorList &&
          mailingAddressLine1ErrorList.length > 0
        ) {
          valid = false;
        }
      }

      const mailingCityErrorList = checkErrors(
        mailingCity,
        VALIDATIONS.ABOUTYOU.CITY
      );
      errorList = { ...errorList, mailingCityErrorList };
      if (mailingCityErrorList && mailingCityErrorList.length > 0) {
        valid = false;
      }

      const mailingProvinceErrorList = checkErrors(
        mailingProvince,
        VALIDATIONS.ABOUTYOU.PROVINCE
      );

      errorList = { ...errorList, mailingProvinceErrorList };
      if (mailingProvinceErrorList && mailingProvinceErrorList.length > 0) {
        valid = false;
      }

      const mailingCountryErrorList = checkErrors(
        mailingCountry,
        VALIDATIONS.ABOUTYOU.COUNTRY
      );
      errorList = { ...errorList, mailingCountryErrorList };
      if (mailingCountryErrorList && mailingCountryErrorList.length > 0) {
        valid = false;
      }

      const postalCodePattern =
        VALIDATIONS.ABOUTYOU[
          `${getSelectedCountry(mailingCountry)}_POSTALCODE`
        ];
      const mailingPostalCodeErrorList = checkErrors(
        mailingPostalCode,
        postalCodePattern
      );
      errorList = { ...errorList, mailingPostalCodeErrorList };
      if (mailingPostalCodeErrorList && mailingPostalCodeErrorList.length > 0) {
        valid = false;
      }
    }

    if (!isDesktop) {
      const scanConfirmationErrorList = checkErrors(
        scanConfirmation,
        VALIDATIONS.ABOUTYOU.SCANCONFIRMATION
      );
      errorList = { ...errorList, scanConfirmationErrorList };
      if (scanConfirmationErrorList && scanConfirmationErrorList.length > 0) {
        valid = false;
      }
    }

    const mailingSameAsCivicErrorList = checkErrors(
      mailingSameAsCivic,
      VALIDATIONS.ABOUTYOU.ADDRESSCONFIRMATION
    );
    errorList = { ...errorList, mailingSameAsCivicErrorList };
    if (mailingSameAsCivicErrorList && mailingSameAsCivicErrorList.length > 0) {
      valid = false;
    }

    if (Object.keys(errorList).length > 0) {
      console.log("validation", errorList);
      this.setState({ errors: errorList });
    }

    return valid;
  };

  toggleButton = (name, choice) => {
    console.log("toggleButton", name, choice);
    const { errors } = this.state;
    this.setState({
      [name]: choice,
      errors: {
        ...errors,
        [`${name}ErrorList`]: [],
      },
    });
    if (!choice) {
      if (name === "mailingSameAsCivic") {
        this.setState({
          blockAddressEdit2: false,
        });
      } else {
        this.setState({
          blockAddressEdit: false,
        });
        scroller.scrollTo("scroll-to-element", {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
        });
      }
    }
    if (name === STRINGS.ABOUTYOU.PARTFIVE.FIELDNAME.SCANCONFIRMATION) {
      this.setState({ editMode: !choice, allowAddressInfoEdit: !choice });
    }

    // if (name === STRINGS.ABOUTYOU.PARTFIVE.FIELDNAME.MAILINGSAMEASCIVIC) {
    //   this.setState({
    //     mailingAddressLine1: "",
    //     mailingAddressLine2: "",
    //     mailingCity: "",
    //     mailingProvince: "",
    //     mailingPostalCode: "",
    //     mailingCountry: "",
    //     errors: {
    //       ...errors,
    //       mailingAddressLine1ErrorList: [],
    //       mailingCityErrorList: [],
    //       mailingProvinceErrorList: [],
    //       mailingPostalCodeErrorList: [],
    //       mailingCountryErrorList: [],
    //       scanConfirmationErrorList: [],
    //       mailingSameAsCivicErrorList: [],
    //     },
    //   });
    // }
  };

  doFindAddress = (value, event) => {
    const {
      executeAdressSearch,
      addressInstanceKey,
      province,
      postalCode,
      city,
      country,
    } = this.state;
    const { doGetAddress, finInfo } = this.props;
    const eventValue = event?.target.value;
    console.log("doFindAddress", value, eventValue);
    this.setState(
      { selectedAddress: [], address: eventValue, blockAddressEdit: false },
      () => {
        if (value && value.length > 4) {
          const params = {
            InstanceKey: addressInstanceKey,
            AddressLine1: value,
            Country: "Canada",
          };
          const addressSearchResult = [];
          doGetAddress(params, (response) => {
            console.log("doGetAddress", response);
            const addressRes = response.data.Output;
            console.log("doGetAddress", addressRes);
            if (addressRes && addressRes.length > 0 && executeAdressSearch) {
              addressRes.forEach((raw, idx) => {
                if (raw.Status !== "F") {
                  if (raw.Ranges && raw.Ranges.length > 0) {
                    raw.Ranges.forEach((range, idx) => {
                      const address = {
                        label: range.FormattedRangeAddress,
                        AddressLine1: raw.AddressLine1.startsWith(range.Range)
                          ? raw.AddressLine1
                          : (range.Range + " " + raw.AddressLine1).trim(),
                        StateProvince: raw.StateProvince,
                        PostalCode: raw.PostalCode,
                        City: raw.City,
                        ProcessedBy: raw.ProcessedBy,
                        Country: finInfo.country[raw.Country],
                        Unit: "",
                      };
                      if (range.UnitsInfo && range.UnitsInfo.length > 0) {
                        range.UnitsInfo.forEach((unit) => {
                          const addr = unit.FormattedUnitAddress.substring(
                            0,
                            unit.FormattedUnitAddress.indexOf(",")
                          );
                          addressSearchResult.push({
                            ...address,
                            label: unit.FormattedUnitAddress,
                            AddressLine1: addr.startsWith(`${unit.UnitInfo}-`)
                              ? addr.substring(addr.indexOf("-") + 1)
                              : addr,
                            Unit: unit.UnitInfo,
                          });
                        });
                      } else {
                        addressSearchResult.push(address);
                      }
                    });
                  } else {
                    const address = {
                      label: raw.FormattedAddress,
                      AddressLine1: raw.FormattedAddress.substring(
                        0,
                        raw.FormattedAddress.indexOf(",")
                      ),
                      StateProvince: raw.StateProvince,
                      PostalCode: raw.PostalCode,
                      City: raw.City,
                      ProcessedBy: raw.ProcessedBy,
                      Country: finInfo.country[raw.Country],
                      Unit: "",
                    };
                    addressSearchResult.push(address);
                  }
                }
              });
            }
            const address = {
              label: STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS,
              AddressLine1: eventValue,
              StateProvince: province,
              PostalCode: postalCode,
              City: city,
              Country: country,
            };
            addressSearchResult.push(address);
            console.log("addressSearchResult", addressSearchResult);
            this.setState({ addressSearchResult, showSearchResult: true });
          });
        }
      }
    );
  };

  doFindAddressOvation = (value, event) => {
    const {
      executeAdressSearch,
      addressInstanceKey,
      province,
      postalCode,
      city,
      country,
      countriesList,
    } = this.state;
    const { doGetAddress, finInfo } = this.props;
    const eventValue = event?.target.value;
    console.log("doFindAddressOvation", value, eventValue);
    this.setState({ selectedAddress: [], blockAddressEdit: false }, () => {
      if (value && value.length > 4) {
        const params = {
          InstanceKey: addressInstanceKey,
          AddressLine1: value,
          Country: "Canada",
        };
        const addressSearchResult = [];
        doGetAddress(params, (response) => {
          console.log("doGetAddress", response);
          const addressRes = response.data.Output;
          console.log("doGetAddress", addressRes);
          if (addressRes && addressRes.length > 0 && executeAdressSearch) {
            addressRes.forEach((raw, idx) => {
              if (raw.Status !== "F") {
                if (raw.Ranges && raw.Ranges.length > 0) {
                  raw.Ranges.forEach((range, idx) => {
                    const streetNo =
                      range.Range?.indexOf("-") > -1
                        ? range.Range.split("-")[1]
                        : range.Range;
                    const address = {
                      label: range.FormattedRangeAddress,
                      AddressLine1: raw.AddressLine1,
                      StreetNumber: streetNo,
                      StreetName: streetNo
                        ? raw.AddressLine1.substring(streetNo.length)?.trim()
                        : raw.AddressLine1,
                      StateProvince: raw.StateProvince,
                      PostalCode: raw.PostalCode,
                      City: raw.City,
                      ProcessedBy: raw.ProcessedBy,
                      Country: finInfo.country[raw.Country],
                      Unit:
                        range.Range?.indexOf("-") > -1
                          ? range.Range.split("-")[0]
                          : "",
                    };
                    if (range.UnitsInfo && range.UnitsInfo.length > 0) {
                      range.UnitsInfo.forEach((unit) => {
                        addressSearchResult.push({
                          ...address,
                          label: unit.FormattedUnitAddress,
                          Unit: unit.UnitInfo,
                        });
                      });
                    } else {
                      addressSearchResult.push(address);
                    }
                  });
                } else {
                  const address = {
                    label: raw.FormattedAddress,
                    AddressLine1: raw.AddressLine1,
                    StreetNumber: raw.HouseNumber,
                    StreetName:
                      raw.AddressLine1.indexOf(`${raw.HouseNumber} `) === -1
                        ? raw.AddressLine1
                        : raw.AddressLine1.substring(
                            raw.HouseNumber.length
                          )?.trim(),
                    StateProvince: raw.StateProvince,
                    PostalCode: raw.PostalCode,
                    City: raw.City,
                    ProcessedBy: raw.ProcessedBy,
                    Country: finInfo.country[raw.Country],
                    Unit: "",
                  };
                  addressSearchResult.push(address);
                }
              }
            });
          }
          const address = {
            label: STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS,
            AddressLine1: eventValue,
            StateProvince: province,
            PostalCode: postalCode,
            City: city,
            Country: country,
          };
          addressSearchResult.push(address);
          console.log("addressSearchResult", addressSearchResult);
          this.setState({ addressSearchResult, showSearchResult: true });
        });
      }
    });
  };

  doFindAddress2Ovation = (value, event) => {
    const {
      executeAdressSearch,
      addressInstanceKey,
      province,
      postalCode,
      city,
      country,
      countriesList,
    } = this.state;
    const { doGetAddress, finInfo } = this.props;
    const eventValue = event?.target.value;
    console.log("doFindAddress2Ovation", value, eventValue);
    this.setState({ selectedAddress2: [], blockAddressEdit2: false }, () => {
      if (value && value.length > 4) {
        const params = {
          InstanceKey: addressInstanceKey,
          AddressLine1: value,
          Country: "Canada",
        };
        const addressSearchResult2 = [];
        doGetAddress(params, (response) => {
          console.log("doGetAddress", response);
          const addressRes = response.data.Output;
          console.log("doGetAddress", addressRes);
          if (addressRes && addressRes.length > 0 && executeAdressSearch) {
            addressRes.forEach((raw, idx) => {
              if (raw.Status !== "F") {
                if (raw.Ranges && raw.Ranges.length > 0) {
                  raw.Ranges.forEach((range, idx) => {
                    const streetNo =
                      range.Range?.indexOf("-") > -1
                        ? range.Range.split("-")[1]
                        : range.Range;
                    const address = {
                      label: range.FormattedRangeAddress,
                      AddressLine1: raw.AddressLine1,
                      StreetNumber: streetNo,
                      StreetName: streetNo
                        ? raw.AddressLine1.substring(streetNo.length)?.trim()
                        : raw.AddressLine1,
                      StateProvince: raw.StateProvince,
                      PostalCode: raw.PostalCode,
                      City: raw.City,
                      ProcessedBy: raw.ProcessedBy,
                      Country: finInfo.country[raw.Country],
                      Unit:
                        range.Range?.indexOf("-") > -1
                          ? range.Range.split("-")[0]
                          : "",
                    };
                    if (range.UnitsInfo && range.UnitsInfo.length > 0) {
                      range.UnitsInfo.forEach((unit) => {
                        addressSearchResult2.push({
                          ...address,
                          label: unit.FormattedUnitAddress,
                          Unit: unit.UnitInfo,
                        });
                      });
                    } else {
                      addressSearchResult2.push(address);
                    }
                  });
                } else {
                  const address = {
                    label: raw.FormattedAddress,
                    AddressLine1: raw.AddressLine1,
                    StreetNumber: raw.HouseNumber,
                    StreetName:
                      raw.AddressLine1.indexOf(`${raw.HouseNumber} `) === -1
                        ? raw.AddressLine1
                        : raw.AddressLine1.substring(
                            raw.HouseNumber.length
                          )?.trim(),
                    StateProvince: raw.StateProvince,
                    PostalCode: raw.PostalCode,
                    City: raw.City,
                    ProcessedBy: raw.ProcessedBy,
                    Country: finInfo.country[raw.Country],
                    Unit: "",
                  };
                  addressSearchResult2.push(address);
                }
              }
            });
          }
          const address = {
            label: STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS,
            AddressLine1: eventValue,
            StateProvince: province,
            PostalCode: postalCode,
            City: city,
            Country: country,
          };
          addressSearchResult2.push(address);
          console.log("addressSearchResult2", addressSearchResult2);
          this.setState({ addressSearchResult2, showSearchResult2: true });
        });
      }
    });
  };

  handleChangeStreet = (event) => {
    console.log("handleChangeStreet", event);
    this.setState({ address: event.target.value });
  };

  getProvinceCode = (code) => {
    const { provinceList } = this.state;
    const province = provinceList.find((item) => item.code === code);
    console.log("getProvinceCode", province);
    if (province) {
      return province.code;
    }
    return "";
  };

  handleChangeAddress = (value) => {
    const { errors } = this.state;
    console.log("handleChangeAddress", value);
    if (value && value.length > 0) {
      if (value[0].label === STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS) {
        this.searchField.current.clear();
      }
      if (value[0].AddressLine1) {
        const address = {
          label: value[0].AddressLine1,
          AddressLine1: value[0].AddressLine1,
          StateProvince: value[0].StateProvince,
          PostalCode: value[0].PostalCode,
          City: value[0].City,
          ProcessedBy: value[0].ProcessedBy,
          Country: value[0].Country,
          Unit: value[0].Unit,
        };
        const selectedAddress = [];
        selectedAddress.push(address);
        this.setState({
          selectedAddress,
          city: value[0].City,
          postalCode: value[0].PostalCode,
          province: this.getProvinceCode(value[0].StateProvince),
          apartment: value[0].Unit,
          address: value[0].AddressLine1,
          showSearchResult: false,
          blockAddressEdit: false,
          errors: {
            ...errors,
            cityErrorList: [],
            postalCodeErrorList: [],
            provinceErrorList: [],
            apartmentErrorList: [],
            addressErrorList: [],
          },
        });
      } else {
        this.setState({
          blockAddressEdit: false,
          showSearchResult: false,
          executeAdressSearch: false,
        });
      }
    }
  };

  handleChangeAddressOvation = (value) => {
    console.log("handleChangeAddressOvation", value);
    const { errors } = this.state;
    if (value && value.length > 0) {
      if (value[0].label === STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS) {
        this.searchField.current.clear();
      }
      if (value[0].AddressLine1) {
        const address = {
          label: value[0].label,
          AddressLine1: value[0].AddressLine1,
          CurrentAddressStreetNumber: value[0].StreetNumber,
          CurrentAddressStreetName: value[0].StreetName,
          StateProvince: value[0].StateProvince,
          PostalCode: value[0].PostalCode,
          City: value[0].City,
          ProcessedBy: value[0].ProcessedBy,
          Country: value[0].Country,
          Unit: value[0].Unit,
        };
        const selectedAddress = [];
        selectedAddress.push(address);
        this.setState({
          selectedAddress,
          city: value[0].City,
          postalCode: value[0].PostalCode,
          province: this.getProvinceCode(value[0].StateProvince),
          apartment: value[0].Unit,
          address: value[0].AddressLine1,
          currentAddressStreetNumber: value[0].StreetNumber,
          currentAddressStreetName: value[0].StreetName,
          showSearchResult: false,
          blockAddressEdit: false,
          errors: {
            ...errors,
            cityErrorList: [],
            postalCodeErrorList: [],
            provinceErrorList: [],
            apartmentErrorList: [],
            addressErrorList: [],
            currentAddressStreetNumberErrorList: [],
            currentAddressStreetNameErrorList: [],
          },
        });
      } else {
        this.setState({
          blockAddressEdit: false,
          showSearchResult: false,
          executeAdressSearch: false,
        });
      }
    }
  };

  doFindAddress2 = (value, event) => {
    const {
      executeAdressSearch2,
      addressInstanceKey,
      mailingProvince,
      mailingPostalCode,
      mailingCity,
      mailingCountry,
      countriesList,
    } = this.state;
    const { doGetAddress, finInfo } = this.props;
    const eventValue = event?.target.value;
    console.log("doFindAddress2", value, eventValue);
    this.setState(
      {
        selectedAddress2: [],
        mailingAddressLine1: eventValue,
        blockAddressEdit2: false,
      },
      () => {
        if (value && value.length > 4) {
          const params = {
            InstanceKey: addressInstanceKey,
            AddressLine1: value,
            Country: "Canada",
          };
          const addressSearchResult2 = [];
          doGetAddress(params, (response) => {
            console.log("doGetAddress", response);
            const addressRes = response.data.Output;
            console.log("doGetAddress", addressRes);
            if (addressRes && addressRes.length > 0 && executeAdressSearch2) {
              addressRes.forEach((raw, idx) => {
                if (raw.Status !== "F") {
                  if (raw.Ranges && raw.Ranges.length > 0) {
                    raw.Ranges.forEach((range, idx) => {
                      const address = {
                        label: range.FormattedRangeAddress,
                        AddressLine1: raw.AddressLine1.startsWith(range.Range)
                          ? raw.AddressLine1
                          : (range.Range + " " + raw.AddressLine1).trim(),
                        StateProvince: raw.StateProvince,
                        PostalCode: raw.PostalCode,
                        City: raw.City,
                        ProcessedBy: raw.ProcessedBy,
                        Country: finInfo.country[raw.Country],
                        Unit: "",
                      };
                      if (range.UnitsInfo && range.UnitsInfo.length > 0) {
                        range.UnitsInfo.forEach((unit) => {
                          const addr = unit.FormattedUnitAddress.substring(
                            0,
                            unit.FormattedUnitAddress.indexOf(",")
                          );
                          addressSearchResult2.push({
                            ...address,
                            label: unit.FormattedUnitAddress,
                            AddressLine1: addr.startsWith(`${unit.UnitInfo}-`)
                              ? addr.substring(addr.indexOf("-") + 1)
                              : addr,
                            Unit: unit.UnitInfo,
                          });
                        });
                      } else {
                        addressSearchResult2.push(address);
                      }
                    });
                  } else {
                    const address = {
                      label: raw.FormattedAddress,
                      AddressLine1: raw.FormattedAddress.substring(
                        0,
                        raw.FormattedAddress.indexOf(",")
                      ),
                      StateProvince: raw.StateProvince,
                      PostalCode: raw.PostalCode,
                      City: raw.City,
                      ProcessedBy: raw.ProcessedBy,
                      Country: finInfo.country[raw.Country],
                      Unit: "",
                    };
                    addressSearchResult2.push(address);
                  }
                }
              });
              const address = {
                label: STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS,
                AddressLine1: eventValue,
                StateProvince: mailingProvince,
                PostalCode: mailingPostalCode,
                City: mailingCity,
                Country: mailingCountry,
              };
              addressSearchResult2.push(address);
              console.log("addressSearchResult", addressSearchResult2);
              this.setState({ addressSearchResult2, showSearchResult2: true });
            }
          });
        }
      }
    );
  };

  handleChangeAddress2 = (value) => {
    console.log("handleChangeAddress2", value);
    const { errors } = this.state;
    if (value && value.length > 0) {
      if (value[0].label === STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS) {
        this.searchField2.current.clear();
      }
      if (value[0].AddressLine1) {
        const address = {
          label: value[0].AddressLine1,
          AddressLine1: value[0].AddressLine1,
          StateProvince: value[0].StateProvince,
          PostalCode: value[0].PostalCode,
          City: value[0].City,
          ProcessedBy: value[0].ProcessedBy,
          Country: value[0].Country,
        };
        const selectedAddress2 = [];
        selectedAddress2.push(address);
        this.setState({
          selectedAddress2,
          mailingCity: value[0].City,
          mailingPostalCode: value[0].PostalCode,
          mailingProvince: this.getProvinceCode(value[0].StateProvince),
          mailingAddressLine1: value[0].AddressLine1,
          mailingCountry: value[0].Country,
          showSearchResult2: false,
          blockAddressEdit2: false,
          errors: {
            ...errors,
            mailingCityErrorList: [],
            mailingPostalCodeErrorList: [],
            mailingProvinceErrorList: [],
            mailingAddressLine1ErrorList: [],
            mailingCountryErrorList: [],
          },
        });
      } else {
        this.setState({
          blockAddressEdit2: false,
          showSearchResult2: false,
          executeAdressSearch2: false,
        });
      }
    }
  };

  handleChangeAddress2Ovation = (value) => {
    console.log("handleChangeAddress2Ovation", value);
    const { errors } = this.state;
    if (value && value.length > 0) {
      if (value[0].label === STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS) {
        this.searchField2.current.clear();
      }
      if (value[0].AddressLine1) {
        const address = {
          label: value[0].label,
          AddressLine1: value[0].AddressLine1,
          StateProvince: value[0].StateProvince,
          PostalCode: value[0].PostalCode,
          City: value[0].City,
          ProcessedBy: value[0].ProcessedBy,
          Country: value[0].Country,
          StreetNumber: value[0].StreetNumber,
          StreetName: value[0].StreetName,
        };
        const selectedAddress2 = [];
        selectedAddress2.push(address);
        this.setState({
          selectedAddress2,
          mailingCity: value[0].City,
          mailingPostalCode: value[0].PostalCode,
          mailingProvince: this.getProvinceCode(value[0].StateProvince),
          mailingAddressLine1: value[0].AddressLine1,
          mailingCountry: value[0].Country,
          mailingStreetNumber: value[0].StreetNumber,
          mailingStreetName: value[0].StreetName,
          showSearchResult2: false,
          blockAddressEdit2: false,
          errors: {
            ...errors,
            mailingCityErrorList: [],
            mailingPostalCodeErrorList: [],
            mailingProvinceErrorList: [],
            mailingAddressLine1ErrorList: [],
            mailingCountryErrorList: [],
            mailingStreetNumberErrorList: [],
            mailingStreetNameErrorList: [],
          },
        });
      } else {
        this.setState({
          blockAddressEdit2: false,
          showSearchResult2: false,
          executeAdressSearch2: false,
        });
      }
    }
  };

  handleAddressMessageClick = () => {
    this.setState({
      addressValidationMessage: "",
      showAddressMessage: false,
    });
  };

  handlePopupModalBtnClick = () => {
    const { history } = this.props;
    history.push(ROUTES.VAULT_DASHBOARD);
  };

  getCurrentActiveProductGroup = () => {
    console.log("getCurrentActiveProductGroup");
    const { getActiveProduct, vaultProductList, productList } = this.props;
    let result = null;
    const activeProduct = getActiveProduct(vaultProductList);
    const currentProductGroup = productList.find((group) => {
      return group?.products?.find(
        (product) => product.productId === activeProduct.productId
      );
    });
    result = productList.find((item) => item.id === currentProductGroup?.id);
    console.log(result);
    return result;
  };

  handleHelpMeChoose = () => {
    console.log("handleHelpMeChoose");
    const {
      history,
      productList,
      doStoreQuestionnaire,
      doStoreQuestionnaireProduct,
    } = this.props;
    const currentProductGroup = this.getCurrentActiveProductGroup();
    console.log(currentProductGroup);
    doStoreQuestionnaire(
      productList.find((item) => item.id === currentProductGroup.id)
        ?.questionnaire
    );
    doStoreQuestionnaireProduct(
      productList.find((item) => item.id === currentProductGroup.id)
    );
    history.push(ROUTES.QUESTIONNAIRE);
  };

  updateKycStatus = (status) => {
    const { doUp } = this.props;
  };

  toggleKyc = (flag) => {
    console.log("toggleKyc", flag);
    this.setState({ doKyc: flag });
  };

  skipKyc = () => {
    const {
      continuehandler,
      doUpdateProductToVault,
      inSessionJointApplicant,
      doSetInSessionJointApplicant,
      vaultProductList,
    } = this.props;
    this.setState({ kycSkipRequested: true });
    const { kycRequest } = this.state;
    console.log("skipKyc", kycRequest);
    this.toggleKyc(false);
    delete kycRequest.kycOnly;
    delete kycRequest.email;
    delete kycRequest.msisdn;
    if (inSessionJointApplicant) {
      inSessionJointApplicant.kycAlreadyDone = true;
      inSessionJointApplicant.instntTxnId = kycRequest.instntTxnId;
      doSetInSessionJointApplicant(inSessionJointApplicant, () => {});
      continuehandler(kycRequest, AppConstants.APPLICATIONSTEP.ABOUTYOU);
    } else {
      if (kycRequest.doVerification && !vaultProductList[0].kycAlreadyDone) {
        vaultProductList[0].kycAlreadyDone = true;
      }
      doUpdateProductToVault(vaultProductList, () => {
        continuehandler(kycRequest, AppConstants.APPLICATIONSTEP.ABOUTYOU);
      });
    }
  };

  refreshAboutYou = () => {
    const {
      history,
      inSessionJointApplicant,
      doGetProductFromVault,
      getActiveProduct,
      doSetInSessionJointApplicant,
      processTemplate,
    } = this.props;
    console.log("refreshAboutYou");
    if (inSessionJointApplicant) {
      doGetProductFromVault((vaultProductList) => {
        console.log("vaultProductList", vaultProductList);
        if (vaultProductList?.status === HTTP_STATUS.OK) {
          const activeProduct = getActiveProduct(vaultProductList.data);
          doSetInSessionJointApplicant(activeProduct.inSessionJointApplicant);
          history.push(activeProduct.inSessionJointApplicant.inSessionNextStep);
        }
      });
    } else {
      history.push(getNextStepAfterKYC(processTemplate));
    }
  };

  render() {
    const {
      userInfo,
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      address,
      apartment,
      city,
      province,
      unitType,
      streetType,
      direction,
      postalCode,
      currentAddressStreetNumber,
      currentAddressStreetName,
      mailingAddressLine1,
      mailingApartment,
      mailingCity,
      mailingProvince,
      mailingPostalCode,
      mailingCountry,
      mailingUnitType,
      mailingStreetType,
      mailingStreetDir,
      mailingStreetNumber,
      mailingStreetName,
      scanConfirmation,
      mailingSameAsCivic,
      errors,
      provinceList,
      editMode,
      countriesList,
      startDate,
      endDate,
      minDate,
      maxDate,
      years,
      months,
      selectedAddress,
      addressSearchResult,
      showSearchResult,
      blockAddressEdit,
      selectedAddress2,
      addressSearchResult2,
      showSearchResult2,
      blockAddressEdit2,
      showModal,
      modal,
      allowPersonalInfoEdit,
      allowAddressInfoEdit,
      showAddressMessage,
      allowKycEditName,
      allowKycEditDob,
      doKyc,
    } = this.state;
    const {
      selfieImage,
      isDesktop,
      productList,
      vaultProductList,
      finInfo,
      instntTxnId,
      getActiveProduct,
      inSessionJointApplicant,
      applicantData,
      sessionCompletedProducts,
      doUpdateProductToVault,
    } = this.props;
    console.log(
      "editMode",
      editMode,
      showAddressMessage,
      !(allowAddressInfoEdit || !mailingSameAsCivic)
    );
    let selfie = null;
    if (selfieImage && selfieImage !== "") {
      selfie = (
        <img className="[ selfie-image ]" src={selfieImage} alt="selfie" />
      );
    } else {
      selfie = <div className="[ icon user-selfie-icon ]" />;
    }
    const dob = dateOfBirth
      ? parse(dateOfBirth, "yyyy-MM-dd", new Date())
      : null;
    console.log(
      "render",
      editMode,
      allowPersonalInfoEdit,
      allowKycEditName,
      city
    );
    const activeProduct = getActiveProduct(vaultProductList);
    const userInformation = (
      <>
        {scanConfirmation === "" && !scanConfirmation && isDesktop && (
          <div className="[ form-description col-12 ]">
            {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.NAME_DISCLAIMER}
          </div>
        )}
        <div className="[ col-12 col-lg-3 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.FIRSTNAMELABEL}
            </div>
            <>
              <input
                type="text"
                name="firstName"
                className="[ form-control ]"
                placeholder={
                  STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.FIRSTNAMELABEL
                }
                value={firstName}
                onChange={this.onChangeHandler}
                readOnly={
                  (!editMode || !allowPersonalInfoEdit) && !allowKycEditName
                }
                ref={(input) => {
                  this.textInput = input;
                }}
              />
              <div className="[ error-msg ]">
                {errors?.firstNameErrorList[0]}
              </div>
            </>
          </div>
        </div>

        <div className="[  col-12 col-lg-3 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.MIDDLENAMELABEL}
            </div>
            <>
              <input
                type="text"
                name="middleName"
                className="[ form-control ]"
                placeholder={
                  STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.MIDDLENAMELABEL
                }
                value={middleName}
                onChange={this.onChangeHandler}
                readOnly={
                  (!editMode || !allowPersonalInfoEdit) && !allowKycEditName
                }
              />
              <div className="[ error-msg ]" />
            </>
          </div>
        </div>

        <div className="[  col-12 col-lg-3 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.LASTNAMELABEL}
            </div>
            <>
              <input
                type="text "
                name="lastName"
                className="[ form-control ]"
                placeholder={STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.LASTNAMELABEL}
                value={lastName}
                onChange={this.onChangeHandler}
                readOnly={
                  (!editMode || !allowPersonalInfoEdit) && !allowKycEditName
                }
              />
              <div className="[ error-msg ]">
                {errors?.lastNameErrorList[0]}
              </div>
            </>
          </div>
        </div>

        <div className="[  col-12 col-lg-3 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.DOBLABEL}
            </div>
            <>
              <DatePicker
                className="[ datepicker ]"
                dateFormat="yyyy-MM-dd"
                placeholderText={`${STRINGS.DOBPLACEHOLDER}`}
                selected={dob}
                onChange={(date) => this.setDOB(date)}
                minDate={startDate}
                maxDate={endDate}
                readOnly={
                  (!editMode || !allowPersonalInfoEdit) && !allowKycEditDob
                }
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div>
                    <button
                      type="button"
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      {"<"}
                    </button>
                    <select
                      value={getYear(date)}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <select
                      value={months[getMonth(date)]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <button
                      type="button"
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      {">"}
                    </button>
                  </div>
                )}
              />
              <div className="[ error-msg ]">
                {errors?.dateOfBirthErrorList[0]}
              </div>
            </>
          </div>
        </div>
      </>
    );

    const product = getProduct(productList, vaultProductList);
    const thankYouCard = (
      <div className="[ thank-you-container ]">
        <div className="[ row ]">
          <div className="[ col-12 ]" />
          <div className="[ col-12 ] [ info-tile__title ]">
            {STRINGS.ABOUTYOU.PARTFIVE.THANKYOUTITLE + firstName}
          </div>
        </div>
        <div className="[ row ]">
          <div className="[ col-12 ] [ info-tile__description ]">
            {STRINGS.ABOUTYOU.PARTFIVE.THANKYOUTEXT}
            {product.productName}
          </div>
        </div>
        <div className="[ row ]">
          <div className="[ col-12 ] [ info-tile__description ]">
            {STRINGS.ABOUTYOU.PARTFIVE.REVIEWREQ}
          </div>
        </div>
      </div>
    );

    const selectList = provinceList.map((elem) => (
      <option
        value={elem.code}
        key={elem.code}
        className="[ dropdown__option ]"
      >
        {elem.description}
      </option>
    ));

    const selectElement = (
      <select
        name="province"
        value={province}
        className={
          errors?.provinceErrorList.length > 0
            ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
            : "[ form-control ] [ select__dropdown ]"
        }
        onChange={this.onChangeHandler}
        disabled={blockAddressEdit || !allowAddressInfoEdit}
        readOnly={!allowAddressInfoEdit}
      >
        <option value="" className="[ default ] [ dropdown__option ]">
          {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
        </option>
        {selectList}
      </select>
    );

    const mailingSelectElement = (
      <select
        name="mailingProvince"
        value={mailingProvince}
        className={
          errors?.mailingProvinceErrorList.length > 0
            ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
            : "[ form-control ] [ select__dropdown ]"
        }
        onChange={this.onChangeHandler}
        disabled={
          blockAddressEdit2 && !(allowAddressInfoEdit || !mailingSameAsCivic)
        }
        readOnly={!(allowAddressInfoEdit || !mailingSameAsCivic)}
      >
        <option value="" className="[ default ] [ dropdown__option ]">
          {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
        </option>
        {selectList}
      </select>
    );

    const countryElem = countriesList.map((country) => (
      <option
        value={country.code}
        key={country.code}
        className="[ dropdown__option ]"
      >
        {country.description}
      </option>
    ));

    const countrySelectElem = (
      <select
        name="mailingCountry"
        value={mailingCountry}
        className={
          errors?.mailingCountryErrorList.length > 0
            ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
            : "[ form-control ] [ select__dropdown ]"
        }
        onChange={this.onChangeHandler}
        disabled={
          blockAddressEdit2 && !(allowAddressInfoEdit || !mailingSameAsCivic)
        }
        readOnly={!(allowAddressInfoEdit || !mailingSameAsCivic)}
      >
        <option value="" className="[ default ] [ dropdown__option ]">
          {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.COUNTRY}
        </option>
        {countryElem}
      </select>
    );

    const addressInputGroup = (
      <div className="[ row ]">
        <div className="col-md-12">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS0}
            </div>
            <>
              <Typeahead
                ref={this.searchField}
                id="basic-typeahead-single"
                className="[ address-suggestions ]"
                clearButton={false}
                onChange={this.handleChangeAddress}
                onInputChange={this.doFindAddress}
                options={addressSearchResult}
                labelKey="label"
                placeholder={
                  STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS_PLACEHOLDER
                }
                selected={selectedAddress}
                emptyLabel={STRINGS.ADDRESS_SEARCH.NO_RECORDS_FOUND}
                open={showSearchResult}
                filterBy={() => true}
                inputProps={{
                  readOnly: !allowAddressInfoEdit,
                }}
                onBlur={() =>
                  this.setState({
                    showSearchResult: false,
                  })
                }
              />
              <div>
                <small>
                  {STRINGS.ADDRESS_SEARCH.SEARCH_MESSAGE}{" "}
                  {STRINGS.ADDRESS_SEARCH.RESIDENTIAL_ADDRESS_REQ}
                </small>
              </div>
              <div className="[ error-msg ]">{errors?.addressErrorList[0]}</div>
            </>
          </div>
        </div>

        <div className="[ col-md-4 ]">
          <div className="[ form-label ]">
            {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.UNIT_LABEL}
          </div>
          <>
            <input
              maxLength={AppConstants.ABOUT_YOU.ADDRESS_LIMIT}
              type="text"
              name="apartment"
              className="[ form-control ]"
              placeholder={STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.IF_APPLICABLE}
              value={apartment}
              onChange={this.onChangeHandler}
              readOnly={!allowAddressInfoEdit}
              disabled={blockAddressEdit}
            />
            <div className="[ error-msg ]">&nbsp;</div>
          </>
        </div>

        <div className="[ col-12 col-md-6 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
            </div>
            <>
              <input
                maxLength={AppConstants.ABOUT_YOU.ADDRESS_LIMIT}
                type="text"
                name="city"
                className={
                  errors?.cityErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ]"
                    : "[ form-control ]"
                }
                placeholder={STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
                value={city}
                onChange={this.onChangeHandler}
                readOnly={!allowAddressInfoEdit}
                disabled={blockAddressEdit}
              />
              <div className="[ error-msg ]">{errors?.cityErrorList[0]}</div>
            </>
          </div>
        </div>

        <div className="[ col-12 col-md-3 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
            </div>
            {/* <div className="[ dropdown ]"> */}
            {selectElement}
            {/* </div> */}
            <div className="[ error-msg ]">{errors?.provinceErrorList[0]}</div>
          </div>
        </div>

        <div className="[ col-12 col-md-3 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE}
            </div>
            <>
              <MaskedInput
                mask={AppConstants.ABOUT_YOU.CA_POSTALMASK}
                className={
                  errors?.postalCodeErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ] [ postalCode ]"
                    : "[ form-control ] [ postalCode ]"
                }
                placeholder={STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE}
                guide={false}
                id="postalCode"
                name="postalCode"
                value={postalCode}
                onChange={this.onChangeHandler}
                readOnly={!allowAddressInfoEdit}
                disabled={blockAddressEdit}
              />
            </>
            <div className="[ error-msg ]">
              {errors?.postalCodeErrorList[0]}
            </div>
          </div>
        </div>
      </div>
    );

    const ovationAddressGroup = (
      <div>
        <div className="[ row ]">
          <div className="[ col-md-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.UNIT_LABEL}
            </div>
            <>
              <input
                type="text"
                name="apartment"
                className="[ form-control ]"
                value={apartment}
                onChange={this.onChangeHandler}
                readOnly={!allowAddressInfoEdit}
                disabled={blockAddressEdit}
              />
              <div className="[ error-msg ]">&nbsp;</div>
            </>
          </div>
          <div className="[ col-md-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.UNIT_TYPE}
            </div>
            <>
              <select
                name="unitType"
                value={unitType}
                className={
                  errors?.provinceErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                    : "[ form-control ] [ select__dropdown ]"
                }
                onChange={this.onChangeHandler}
                disabled={blockAddressEdit || !allowAddressInfoEdit}
                readOnly={!allowAddressInfoEdit}
              >
                {unitTypeList.map((e) => {
                  return (
                    <option value={e} className="[ dropdown__option ]">
                      {e}
                    </option>
                  );
                })}
              </select>
              <div className="[ error-msg ]">&nbsp;</div>
            </>
          </div>
        </div>
        <div className="[ row ]">
          <div className="col-md-2">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.STREET_NO}
            </div>
            <>
              <input
                type="text"
                name="currentAddressStreetNumber"
                className={
                  errors?.currentAddressStreetNumberErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ]"
                    : "[ form-control ]"
                }
                placeholder=""
                value={currentAddressStreetNumber}
                onChange={this.onChangeHandler}
                readOnly={!allowAddressInfoEdit}
                disabled={blockAddressEdit}
              />
              <div className="[ error-msg ]">
                {errors?.currentAddressStreetNumberErrorList[0]}
              </div>
            </>
          </div>
          <div className="col-md-4">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.STREET_NAME}
            </div>
            <>
              <input
                type="text"
                name="currentAddressStreetName"
                className={
                  errors?.currentAddressStreetNameErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ]"
                    : "[ form-control ]"
                }
                placeholder=""
                value={currentAddressStreetName}
                onChange={this.onChangeHandler}
                readOnly={!allowAddressInfoEdit}
                disabled={blockAddressEdit}
              />
              <div className="[ error-msg ]">
                {errors?.currentAddressStreetNameErrorList[0]}
              </div>
            </>
          </div>
          <div className="[ col-md-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.STREET_TYPE}
            </div>
            <>
              <select
                name="streetType"
                value={streetType}
                className="[ form-control ] [ select__dropdown ]"
                onChange={this.onChangeHandler}
                disabled={blockAddressEdit || !allowAddressInfoEdit}
                readOnly={!allowAddressInfoEdit}
              >
                {streetTypeList.map((e) => {
                  return (
                    <option value={e} className="[ dropdown__option ]">
                      {e}
                    </option>
                  );
                })}
              </select>
              <div className="[ error-msg ]">&nbsp;</div>
            </>
          </div>
          <div className="[ col-md-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.DIRECTION}
            </div>
            <>
              <select
                name="direction"
                value={direction}
                className="[ form-control ] [ select__dropdown ]"
                onChange={this.onChangeHandler}
                disabled={blockAddressEdit || !allowAddressInfoEdit}
                readOnly={!allowAddressInfoEdit}
              >
                {directionList.map((e) => {
                  return (
                    <option value={e} className="[ dropdown__option ]">
                      {e}
                    </option>
                  );
                })}
              </select>
              <div className="[ error-msg ]">&nbsp;</div>
            </>
          </div>
        </div>
        <div className="[ row ]">
          <div className="[ col-12 col-md-6 ]">
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
              </div>
              <>
                <input
                  type="text"
                  name="city"
                  className={
                    errors?.cityErrorList.length > 0
                      ? "[ form-control ] [ invalid-input ]"
                      : "[ form-control ]"
                  }
                  placeholder={STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
                  value={city}
                  onChange={this.onChangeHandler}
                  readOnly={!allowAddressInfoEdit}
                  disabled={blockAddressEdit}
                />
                <div className="[ error-msg ]">{errors?.cityErrorList[0]}</div>
              </>
            </div>
          </div>

          <div className="[ col-12 col-md-3 ]">
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
              </div>
              {/* <div className="[ dropdown ]"> */}
              {selectElement}
              {/* </div> */}
              <div className="[ error-msg ]">
                {errors?.provinceErrorList[0]}
              </div>
            </div>
          </div>

          <div className="[ col-12 col-md-3 ]">
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE}
              </div>
              <>
                <MaskedInput
                  mask={AppConstants.ABOUT_YOU.CA_POSTALMASK}
                  className={
                    errors?.postalCodeErrorList.length > 0
                      ? "[ form-control ] [ invalid-input ] [ postalCode ]"
                      : "[ form-control ] [ postalCode ]"
                  }
                  placeholder={STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE}
                  guide={false}
                  id="postalCode"
                  name="postalCode"
                  value={postalCode}
                  onChange={this.onChangeHandler}
                  readOnly={!allowAddressInfoEdit}
                  disabled={blockAddressEdit}
                />
              </>
              <div className="[ error-msg ]">
                {errors?.postalCodeErrorList[0]}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const confirmQuestion = (
      <div>
        <div className="[ divider ] [ d-xl-none ]" />
        <div className="[ mb-3 ]">
          <div className="[ col-12 ] [ alert alert-warning ]" role="alert">
            <i className="[ fas fa-exclamation-circle ]" />
            <span>{STRINGS.ABOUTYOU.PARTFIVE.CONFIRMQUESTION}</span>
          </div>

          <div className="[ row ] [ custom-radios ]">
            <div className="[ col-6 ]">
              <button
                type="button"
                className={
                  scanConfirmation !== "" && scanConfirmation
                    ? "[ btn custom-radio-selected ] "
                    : "[ btn custom-radio-unselected ] "
                }
                onClick={(event) =>
                  this.toggleButton(
                    STRINGS.ABOUTYOU.PARTFIVE.FIELDNAME.SCANCONFIRMATION,
                    true,
                    event
                  )
                }
              >
                {STRINGS.COMMON.CHOICE.YES}
              </button>
            </div>

            <div className="[ col-6 ]">
              <button
                type="button"
                className={
                  scanConfirmation !== "" && !scanConfirmation
                    ? "[ btn custom-radio-selected ] "
                    : "[ btn custom-radio-unselected ] "
                }
                onClick={(event) =>
                  this.toggleButton(
                    STRINGS.ABOUTYOU.PARTFIVE.FIELDNAME.SCANCONFIRMATION,
                    false,
                    event
                  )
                }
              >
                {STRINGS.COMMON.CHOICE.NO}
              </button>
            </div>
          </div>

          {scanConfirmation !== "" && (
            <div className="[ error-msg ]">
              {errors?.scanConfirmationErrorList[0]}
            </div>
          )}
        </div>
        <div className="[ divider ] [ d-xl-none ]" />
      </div>
    );

    const countryCode = getSelectedCountry(mailingCountry);

    const mailingPostalCodePattern =
      AppConstants.ABOUT_YOU[`${countryCode}_POSTALMASK`];

    return (
      <>
        {doKyc && (
          <PopupKyc
            showModal={doKyc}
            vaultProductList={vaultProductList}
            getActiveProduct={getActiveProduct}
            doUpdateProductToVault={doUpdateProductToVault}
            toggleKyc={this.toggleKyc}
            refreshAboutYou={this.refreshAboutYou}
            skipKyc={this.skipKyc}
          />
        )}
        {showModal && (
          <PopupModal
            modalType={AppConstants.MODALTYPE.CUSTOM}
            title={modal.title}
            description={modal.description}
            showModal={showModal}
            popupBtnClick={() => this.handlePopupModalBtnClick()}
            closeBtnClick={() => this.handlePopupModalBtnClick()}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
            ageRestriction={
              modal.ageRestriction ? () => this.handleHelpMeChoose() : null
            }
          />
        )}
        {showAddressMessage && (
          <PopupModal
            modalType={AppConstants.MODALTYPE.CUSTOM}
            title={modal.title}
            description={modal.description}
            showModal={showAddressMessage}
            popupBtnClick={() => this.handleAddressMessageClick()}
            closeBtnClick={() => this.handleAddressMessageClick()}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <div>{userInfo}</div>

        <div className="[ form-container ]">
          <div className="[ row ] [ d-xl-none ] [ mb-3 ]">
            {selfieImage ? (
              <>
                <div className="[ col-12 col-lg-4 ]">
                  <div className="[ selfie-container ]">{selfie}</div>
                </div>
                <div className="[ col-12 col-lg-8 ]">{thankYouCard}</div>
              </>
            ) : (
              <div className="[ col-12 ]">
                <div className="[ selfie-container ]">{selfie}</div>
              </div>
            )}
            <Element name="scroll-to-element" />
          </div>
          <div className="[ divider ] [ d-xl-none ]" />
          <div className="[ form-subtitle ]">
            {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.PERSONALSECTION}
          </div>
          {scanConfirmation !== "" && !scanConfirmation && (
            <div className="[ col-12 ] [ alert alert-danger ]" role="alert">
              <i className="[ fas fa-exclamation-circle ]" />
              <span>{STRINGS.ABOUTYOU.PARTFIVE.CONFIRMMESSAGE}</span>
            </div>
          )}
          {firstName ? (
            <>
              <div className="[ row ]">{userInformation}</div>
            </>
          ) : (
            <div className="[ row ]">{userInformation}</div>
          )}

          <div className="[ divider ] [ d-xl-none ]" />
          {finInfo.coreBankingType ===
            AppConstants.CORE_BANKING_TYPE.OVATION && (
            <>
              <div className="[ form-subtitle ]">
                {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.SEARCH_ADDRESS}
              </div>
              <div className="[ row ]">
                <div className="col-md-12">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS0}
                    </div>
                    <>
                      <Typeahead
                        ref={this.searchField}
                        id="basic-typeahead-single"
                        className="[ address-suggestions ]"
                        clearButton={false}
                        onChange={this.handleChangeAddressOvation}
                        onInputChange={this.doFindAddressOvation}
                        options={addressSearchResult}
                        labelKey="label"
                        emptyLabel={STRINGS.ADDRESS_SEARCH.NO_RECORDS_FOUND}
                        open={showSearchResult}
                        filterBy={() => true}
                        inputProps={{
                          readOnly: !allowAddressInfoEdit,
                        }}
                        onBlur={() =>
                          this.setState({
                            showSearchResult: false,
                          })
                        }
                      />
                      <div>
                        <small>
                          {STRINGS.ADDRESS_SEARCH.SEARCH_MESSAGE}{" "}
                          {STRINGS.ADDRESS_SEARCH.RESIDENTIAL_ADDRESS_REQ}
                        </small>
                      </div>
                    </>
                  </div>
                </div>
              </div>
              <div className="[ form-subtitle ]">
                {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.EDIT_ADDRESS}
              </div>
              {ovationAddressGroup}
            </>
          )}
          {finInfo.coreBankingType !==
            AppConstants.CORE_BANKING_TYPE.OVATION && (
            <>
              <div className="[ form-subtitle ]">
                {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESSSECTION}
              </div>
              {!isNewMember(applicantData) && (
                <div className="[ form-description ]">
                  {STRINGS.ABOUTYOU.MEMBER_MESSAGE}
                </div>
              )}
              {addressInputGroup}
            </>
          )}

          {isDesktop ? null : confirmQuestion}

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSCONFIRMQUESTION}
            </div>

            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    mailingSameAsCivic !== "" && mailingSameAsCivic
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  onClick={(event) =>
                    this.toggleButton(
                      STRINGS.ABOUTYOU.PARTFIVE.FIELDNAME.MAILINGSAMEASCIVIC,
                      true,
                      event
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>

              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    mailingSameAsCivic !== "" && !mailingSameAsCivic
                      ? "[ btn custom-radio-selected ] "
                      : "[ btn custom-radio-unselected ] "
                  }
                  onClick={(event) =>
                    this.toggleButton(
                      STRINGS.ABOUTYOU.PARTFIVE.FIELDNAME.MAILINGSAMEASCIVIC,
                      false,
                      event
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
            </div>
            <div className="[ error-msg ]">
              {errors?.mailingSameAsCivicErrorList[0]}
            </div>
          </div>

          {finInfo.coreBankingType !== AppConstants.CORE_BANKING_TYPE.OVATION &&
          mailingSameAsCivic !== "" &&
          !mailingSameAsCivic ? (
            <div className="[ drawer-content-container mb-3 ]">
              <Element name="scroll-to-mailing" />
              <div className="[ info-title ]">
                {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.MAILINGSECTION}
              </div>

              <div className="[ row ]">
                <div className="[ col-md-8 ]">
                  <div className="[ form-label ]">
                    {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS0}
                  </div>
                  <Typeahead
                    ref={this.searchField2}
                    id="mailingAddressLine1"
                    className="[ address-suggestions ]"
                    clearButton={false}
                    onChange={this.handleChangeAddress2}
                    onInputChange={this.doFindAddress2}
                    options={addressSearchResult2}
                    labelKey="label"
                    placeholder={
                      STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS_PLACEHOLDER
                    }
                    selected={selectedAddress2}
                    emptyLabel={STRINGS.ADDRESS_SEARCH.NO_RECORDS_FOUND}
                    open={showSearchResult2}
                    filterBy={() => true}
                    inputProps={{
                      readOnly: !(allowAddressInfoEdit || !mailingSameAsCivic),
                    }}
                    onBlur={() =>
                      this.setState({
                        showSearchResult2: false,
                      })
                    }
                  />
                  <div>
                    <small>{STRINGS.ADDRESS_SEARCH.SEARCH_MESSAGE}</small>
                  </div>
                  <div className="[ error-msg ]">
                    {errors?.mailingAddressLine1ErrorList[0]}
                  </div>
                </div>

                <div className="[ col-md-4 ]">
                  <div className="[ form-label ]">
                    {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.UNIT_LABEL}
                  </div>
                  <>
                    <input
                      maxLength={AppConstants.ABOUT_YOU.ADDRESS_LIMIT}
                      type="text"
                      name="mailingApartment"
                      className="[ form-control ]"
                      placeholder={
                        STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.IF_APPLICABLE
                      }
                      value={mailingApartment}
                      onChange={this.onChangeHandler}
                      disabled={
                        blockAddressEdit2 &&
                        !(allowAddressInfoEdit || !mailingSameAsCivic)
                      }
                      readOnly={!(allowAddressInfoEdit || !mailingSameAsCivic)}
                    />
                  </>
                  <div className="[ error-msg ]" />
                </div>

                <div className="[ col-12 col-md-6 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
                    </div>
                    <>
                      <input
                        maxLength={AppConstants.ABOUT_YOU.ADDRESS_LIMIT}
                        type="text"
                        name="mailingCity"
                        className={
                          errors?.mailingCityErrorList.length > 0
                            ? "[ form-control ] [ invalid-input ]"
                            : "[ form-control ]"
                        }
                        placeholder={STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
                        value={mailingCity}
                        onChange={this.onChangeHandler}
                        disabled={
                          blockAddressEdit2 &&
                          !(allowAddressInfoEdit || !mailingSameAsCivic)
                        }
                        readOnly={
                          !(allowAddressInfoEdit || !mailingSameAsCivic)
                        }
                      />
                    </>
                    <div className="[ error-msg ]">
                      {errors?.mailingCityErrorList[0]}
                    </div>
                  </div>
                </div>

                <div className="[ col-12 col-md-6 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
                    </div>
                    {/* <div className="[ dropdown ]"> */}
                    {mailingSelectElement}
                    {/* </div> */}
                    <div className="[ error-msg ]">
                      {errors?.mailingProvinceErrorList[0]}
                    </div>
                  </div>
                </div>

                <div className="[ col-12 col-md-6 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.COUNTRY}
                    </div>
                    {/* <div className="[ dropdown ]"> */}
                    {countrySelectElem}
                    {/* </div> */}
                    <div className="[ error-msg ]">
                      {errors?.mailingCountryErrorList[0]}
                    </div>
                  </div>
                </div>

                <div className="[ col-12 col-md-6 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE}
                    </div>
                    <>
                      <MaskedInput
                        mask={mailingPostalCodePattern}
                        className={
                          errors?.mailingPostalCodeErrorList.length > 0
                            ? "[ form-control ] [ invalid-input ] [ postalCode ]"
                            : "[ form-control ] [ postalCode ]"
                        }
                        placeholder={
                          STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE
                        }
                        guide={false}
                        id="mailingPostalCode"
                        name="mailingPostalCode"
                        value={mailingPostalCode}
                        onChange={this.onChangeHandler}
                        disabled={
                          blockAddressEdit2 &&
                          !(allowAddressInfoEdit || !mailingSameAsCivic)
                        }
                        readOnly={
                          !(allowAddressInfoEdit || !mailingSameAsCivic)
                        }
                      />
                    </>
                    <div className="[ error-msg ]">
                      {errors?.mailingPostalCodeErrorList[0]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {finInfo.coreBankingType === AppConstants.CORE_BANKING_TYPE.OVATION &&
          mailingSameAsCivic !== "" &&
          !mailingSameAsCivic ? (
            <div className="[ drawer-content-container mb-3 ]">
              <div className="[ info-title ]">
                {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.MAILINGSECTION}
              </div>
              <div className="[ row ]">
                <div className="col-md-12">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS0}
                    </div>
                    <div>
                      <Typeahead
                        ref={this.searchField2}
                        id="mailingSearchField"
                        className="[ address-suggestions ]"
                        clearButton={false}
                        onChange={this.handleChangeAddress2Ovation}
                        onInputChange={this.doFindAddress2Ovation}
                        options={addressSearchResult2}
                        labelKey="label"
                        emptyLabel={STRINGS.ADDRESS_SEARCH.NO_RECORDS_FOUND}
                        open={showSearchResult2}
                        filterBy={() => true}
                        inputProps={{
                          readOnly: !(
                            allowAddressInfoEdit || !mailingSameAsCivic
                          ),
                        }}
                        onBlur={() =>
                          this.setState({
                            showSearchResult2: false,
                          })
                        }
                      />
                      <div>
                        <small>
                          {STRINGS.ADDRESS_SEARCH.SEARCH_MESSAGE}{" "}
                          {STRINGS.ADDRESS_SEARCH.RESIDENTIAL_ADDRESS_REQ}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="[ row ]">
                <div className="[ col-md-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.UNIT_LABEL}
                  </div>
                  <>
                    <input
                      type="text"
                      name="mailingApartment"
                      className="[ form-control ]"
                      placeholder={
                        STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.IF_APPLICABLE
                      }
                      value={mailingApartment}
                      onChange={this.onChangeHandler}
                      disabled={
                        blockAddressEdit2 &&
                        !(allowAddressInfoEdit || !mailingSameAsCivic)
                      }
                      readOnly={!(allowAddressInfoEdit || !mailingSameAsCivic)}
                    />
                  </>
                  <div className="[ error-msg ]" />
                </div>
                <div className="[ col-md-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.UNIT_TYPE}
                  </div>
                  <>
                    <select
                      name="mailingUnitType"
                      value={mailingUnitType}
                      className="[ form-control ] [ select__dropdown ]"
                      onChange={this.onChangeHandler}
                      disabled={
                        blockAddressEdit2 &&
                        !(allowAddressInfoEdit || !mailingSameAsCivic)
                      }
                      readOnly={!(allowAddressInfoEdit || !mailingSameAsCivic)}
                    >
                      {unitTypeList.map((e) => {
                        return (
                          <option value={e} className="[ dropdown__option ]">
                            {e}
                          </option>
                        );
                      })}
                    </select>
                    <div className="[ error-msg ]">&nbsp;</div>
                  </>
                </div>
              </div>
              <div className="[ row ]">
                <div className="[ col-md-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.STREET_NO}
                  </div>
                  <input
                    type="text"
                    name="mailingStreetNumber"
                    className="[ form-control ]"
                    value={mailingStreetNumber}
                    onChange={this.onChangeHandler}
                    disabled={
                      blockAddressEdit2 &&
                      !(allowAddressInfoEdit || !mailingSameAsCivic)
                    }
                    readOnly={!(allowAddressInfoEdit || !mailingSameAsCivic)}
                  />
                  <div className="[ error-msg ]">
                    {errors?.mailingStreetNumberErrorList[0]}
                  </div>
                </div>
                <div className="[ col-md-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.STREET_NAME}
                  </div>
                  <input
                    type="text"
                    name="mailingStreetName"
                    className="[ form-control ]"
                    value={mailingStreetName}
                    onChange={this.onChangeHandler}
                    disabled={
                      blockAddressEdit2 &&
                      !(allowAddressInfoEdit || !mailingSameAsCivic)
                    }
                    readOnly={!(allowAddressInfoEdit || !mailingSameAsCivic)}
                  />
                  <div className="[ error-msg ]">
                    {errors?.mailingStreetNameErrorList[0]}
                  </div>
                </div>
                <div className="[ col-md-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.STREET_TYPE}
                  </div>
                  <>
                    <select
                      name="mailingStreetType"
                      value={mailingStreetType}
                      className="[ form-control ] [ select__dropdown ]"
                      onChange={this.onChangeHandler}
                      disabled={
                        blockAddressEdit2 &&
                        !(allowAddressInfoEdit || !mailingSameAsCivic)
                      }
                      readOnly={!(allowAddressInfoEdit || !mailingSameAsCivic)}
                    >
                      {streetTypeList.map((e) => {
                        return (
                          <option value={e} className="[ dropdown__option ]">
                            {e}
                          </option>
                        );
                      })}
                    </select>
                    <div className="[ error-msg ]">&nbsp;</div>
                  </>
                </div>
                <div className="[ col-md-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.DIRECTION}
                  </div>
                  <>
                    <select
                      name="mailingStreetDir"
                      value={mailingStreetDir}
                      className="[ form-control ] [ select__dropdown ]"
                      onChange={this.onChangeHandler}
                      disabled={
                        blockAddressEdit2 &&
                        !(allowAddressInfoEdit || !mailingSameAsCivic)
                      }
                      readOnly={!(allowAddressInfoEdit || !mailingSameAsCivic)}
                    >
                      {directionList.map((e) => {
                        return (
                          <option value={e} className="[ dropdown__option ]">
                            {e}
                          </option>
                        );
                      })}
                    </select>
                    <div className="[ error-msg ]">&nbsp;</div>
                  </>
                </div>
              </div>
              <div className="[ row ]">
                <div className="[ col-12 col-md-6 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
                    </div>
                    <>
                      <input
                        type="text"
                        name="mailingCity"
                        className={
                          errors?.mailingCityErrorList.length > 0
                            ? "[ form-control ] [ invalid-input ]"
                            : "[ form-control ]"
                        }
                        placeholder={STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
                        value={mailingCity}
                        onChange={this.onChangeHandler}
                        disabled={
                          blockAddressEdit2 &&
                          !(allowAddressInfoEdit || !mailingSameAsCivic)
                        }
                        readOnly={
                          !(allowAddressInfoEdit || !mailingSameAsCivic)
                        }
                      />
                    </>
                    <div className="[ error-msg ]">
                      {errors?.mailingCityErrorList[0]}
                    </div>
                  </div>
                </div>
                <div className="[ col-12 col-md-3 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
                    </div>
                    {/* <div className="[ dropdown ]"> */}
                    {mailingSelectElement}
                    {/* </div> */}
                    <div className="[ error-msg ]">
                      {errors?.mailingProvinceErrorList[0]}
                    </div>
                  </div>
                </div>
                <div className="[ col-12 col-md-3 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.COUNTRY}
                    </div>
                    {/* <div className="[ dropdown ]"> */}
                    {countrySelectElem}
                    {/* </div> */}
                    <div className="[ error-msg ]">
                      {errors?.mailingCountryErrorList[0]}
                    </div>
                  </div>
                </div>
              </div>
              <div className="[ row ]">
                <div className="[ col-12 col-md-6 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE}
                    </div>
                    <>
                      <MaskedInput
                        mask={mailingPostalCodePattern}
                        className={
                          errors?.mailingPostalCodeErrorList.length > 0
                            ? "[ form-control ] [ invalid-input ] [ postalCode ]"
                            : "[ form-control ] [ postalCode ]"
                        }
                        placeholder={
                          STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE
                        }
                        guide={false}
                        id="mailingPostalCode"
                        name="mailingPostalCode"
                        value={mailingPostalCode}
                        onChange={this.onChangeHandler}
                        disabled={
                          blockAddressEdit2 &&
                          !(allowAddressInfoEdit || !mailingSameAsCivic)
                        }
                        readOnly={
                          !(allowAddressInfoEdit || !mailingSameAsCivic)
                        }
                      />
                    </>
                    <div className="[ error-msg ]">
                      {errors?.mailingPostalCodeErrorList[0]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {(instntTxnId ||
            (activeProduct?.applicantIdb &&
              !allowKycEdit(
                applicantData,
                sessionCompletedProducts,
                finInfo,
                inSessionJointApplicant
              )) ||
            (activeProduct?.applicationId &&
              !inSessionJointApplicant &&
              !activeProduct.isJointProduct) ||
            (inSessionJointApplicant &&
              inSessionJointApplicant?.applicantId &&
              !allowKycEditForJointInSession(applicantData, finInfo))) && (
            <div className="[ mb-3 ]">
              <div className="[ row ]">
                <div className="[ col-lg-6 ] [ offset-lg-3 ]">
                  <button
                    type="button"
                    className="[ btn  submit-btn ] [ w-100 ] "
                    onClick={this.submitData}
                  >
                    {STRINGS.COMMON.CONTINUEBTN}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

Review.propTypes = {
  selfieImage: PropTypes.string,
  fName: PropTypes.string,
  mName: PropTypes.string,
  lName: PropTypes.string,
  dOB: PropTypes.string,
  address: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  province: PropTypes.string,
  postalCode: PropTypes.string,
  idType: PropTypes.string,
  documentNumber: PropTypes.string,
  expirationDate: PropTypes.string,
  issueDate: PropTypes.string,
  addressState: PropTypes.string,
  provinceList: PropTypes.arrayOf(PropTypes.object),
  isDesktop: PropTypes.bool,
  countryList: PropTypes.arrayOf(PropTypes.object),
  doGetCountryList: PropTypes.func.isRequired,
  doGetGettingStartedVault: PropTypes.func.isRequired,
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  doGetProvinceData: PropTypes.func.isRequired,
  instntTxnId: PropTypes.string,
  vaultProductList: PropTypes.arrayOf(PropTypes.object).isRequired,
  productList: PropTypes.arrayOf(PropTypes.object).isRequired,
  getActiveProduct: PropTypes.func,
  inSessionJointApplicant: PropTypes.oneOfType([PropTypes.object]),
  applicationCompleteList: PropTypes.arrayOf(PropTypes.object),
  finInfo: PropTypes.oneOfType([PropTypes.object]),
  fingerprint: PropTypes.string,
  idTypes: PropTypes.arrayOf(PropTypes.object),
  idName: PropTypes.string,
  applicantData: PropTypes.arrayOf(PropTypes.object),
  sessionCompletedProducts: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

Review.defaultProps = {
  selfieImage: "",
  fName: "",
  mName: "",
  lName: "",
  dOB: "",
  address: "",
  addressLine2: "",
  city: "",
  province: "",
  postalCode: "",
  idType: "",
  documentNumber: "",
  expirationDate: "",
  addressState: "",
  isDesktop: true,
  countryList: [],
  provinceList: [],
  instntTxnId: "",
  getActiveProduct: () => {},
  inSessionJointApplicant: null,
  applicationCompleteList: [],
  finInfo: {},
  fingerprint: "",
  idTypes: [],
  idName: "",
  applicantData: {},
};

const mapStateToProps = (state) => ({
  provinceList: state.AboutYouReducer.provinceList,
  getIsApplicantUpdated: state.UpdateApplicantReducer,
  countryList: state.EmploymentAndIncomeReducer.countryList,
  vaultProductList: state.VaultReducer.vaultProductList,
  productList: state.MarketplaceReducer.productList,
  inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
  finInfo: state.MarketplaceReducer.finInfo,
  applicantData: state.ApplicationReducer.response,
  idTypes: state.AboutYouReducer.idTypes,
  sessionCompletedProducts: state.ApplicationReducer.sessionCompletedProducts,
  vaultMember: state.ApplicationReducer.vaultMember,
  membershipsData: state.ApplicationReducer.membershipsData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetCountryList: () => dispatch(getCountryList()),
    doGetGettingStartedVault: (callback) =>
      dispatch(getGettingStartedData(callback)),
    doGetProvinceData: () => dispatch(doGetProvinceList()),
    doAddressKey: (callback) => dispatch(doAddressKey(callback)),
    doGetAddress: (params, callback) =>
      dispatch(doGetAddress(params, callback)),
    doUpdateApplicant: (request, applicantId, callback) =>
      dispatch(updateApplicantData(request, applicantId, callback)),
    doStoreQuestionnaire: (questionnaire) =>
      dispatch(storeQuestionnaire(questionnaire)),
    doStoreQuestionnaireProduct: (product) =>
      dispatch(storeQuestionnaireProduct(product)),
    doUpdateVaultMember: (member, callback) =>
      dispatch(updateVaultMember(member, callback)),
    doSetInSessionJointApplicant: (request, callback) =>
      dispatch(setInSessionJointApplicant(request, callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
  };
};

const ReviewWithRouter = withRouter(Review);
export default connect(mapStateToProps, mapDispatchToProps)(ReviewWithRouter);
