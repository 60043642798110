/* eslint-disable no-console */
import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import checkErrors from "Utils/InputValidator";
import VALIDATIONS from "Constants/ValidationList";
import STRINGS from "Constants/Strings";
import { Modal } from "react-bootstrap";
import {
  InstntDocumentProcessor,
  InstntImageProcessor,
} from "@instnt/instnt-react-js";

class ScanIdFront extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scanError: "",
      capture: null,
      capturedImage: null,
      showHelp: false,
    };
  }

  componentDidMount() {
    // this.setState({ capture: window.IDMetricsCaptureFramework });
  }

  scanFrontID = () => {
    const { doScan } = this.props;
    doScan(true);
    // const { capture } = this.state;
    // const { idType } = this.props;
    // const captureResult = new window.IDMetricsCaptureFramework.CaptureResult();
    // const docSettings = new window.IDMetricsCaptureFramework.DocumentSettings();
    // docSettings.documentSide =
    //   STRINGS.ABOUTYOU.PARTTWO.DOCUMENTSETTING.DOCUMENTSIDE;
    // docSettings.documentType = idType;
    // docSettings.captureMode = STRINGS.ABOUTYOU.COMMONPART.CAPTUREMODE;
    // docSettings.backGlareThreshold =
    //   STRINGS.ABOUTYOU.PARTTWO.DOCUMENTSETTING.GLARETHRESHOLD;
    // capture.scanDocument(docSettings, captureResult);
    // captureResult.setOnFinish(() => this.scanResult(captureResult));
    // captureResult.setOnEvent((statusCode, description, data) => {
    //   console.debug(
    //     `DOC EVENT! Code=${statusCode}, Description=${description}`
    //   );
    //   console.debug(data);
    //   console.debug(docSettings);
    //   captureResult.continue();
    // });
  };

  scanResult = (captureResult) => {
    this.setState({ capturedImage: captureResult.result, scanError: "" });
  };

  submitData = (imgSrc) => {
    if (this.verifyData(imgSrc)) {
      const { scanIdFrontHandler } = this.props;
      scanIdFrontHandler(imgSrc);
    }
  };

  verifyData = (imgSrc) => {
    const imageSource = imgSrc || "";
    const idErrorsList = checkErrors(imageSource, VALIDATIONS.ABOUTYOU.IDFRONT);
    if (idErrorsList && idErrorsList.length > 0) {
      this.setState({ scanError: idErrorsList });
      return false;
    }
    return true;
  };

  handleCloseHelp = () => {
    this.setState({
      showHelp: false,
    });
  };

  handleOpenHelp = () => {
    this.setState({
      showHelp: true,
    });
  };

  render() {
    const { scanError, showHelp } = this.state;
    const { idFront, idType, doCapture, capturedImage } = this.props;
    let scanErrorDOM = "";
    if (scanError) {
      scanErrorDOM = (
        <div className="[ scan-error__card ]">
          {scanError.map((error) => (
            <div className="[ error-msg ]" key="error-msg">
              {error}
            </div>
          ))}
          <div className="[ row ] [ info-row ]" />
          <div className="[ row ] [ info-row ] " />
        </div>
      );
    }
    let imageDiv = (
      <div
        className=" [ scan-id__card ] "
        onClick={this.scanFrontID}
        onKeyDown={this.handleKeyDown}
        role="button"
        tabIndex="0"
      >
        <div className="[ flex-row justify-content-center text-center ]">
          <i className="[ fas fa-id-card ]" />
        </div>
        <div className="[ scan-id__text] ">{STRINGS.ABOUTYOU.CLICKTOBEGIN}</div>
      </div>
    );
    let imgSrc = null;
    if (idFront) {
      imgSrc = idFront;
    }
    if (capturedImage) {
      imgSrc = capturedImage;
    }
    if (imgSrc) {
      imageDiv = (
        <div className="[ scan-id__card ]">
          <img
            className="[ scan-id__card__img ]"
            src={imgSrc}
            alt="scanned front side ID"
          />
        </div>
      );
    }
    const frontLicenseSettings = {
      documentType: idType,
      documentSide: "Front",
      frontFocusThreshold: 30,
      frontGlareThreshold: 8,
      frontCaptureAttempts: 4,
      captureMode: "Auto",
      overlayText: STRINGS.ABOUTYOU.PARTTWO.OVERLAY_FRONT_ID,
      overlayTextAuto: STRINGS.ABOUTYOU.PARTTWO.OVERLAY_FRONT_ID_AUTOTAKE,
      overlayColor: "yellow",
      orientationErrorText: STRINGS.ABOUTYOU.PARTTWO.ERROR_PORTRAIT,
      enableFaceDetection: true,
      captureTimeout: 5000,
      backFocusThreshold: 30,
      backGlareThreshold: 10,
      backCaptureAttempts: 4,
      isBarcodeDetectedEnabled: false,
      enableLocationDetection: false,
      orientationErrorText:
        "Please hold your device vertically and rotate sideways to continue",
    };
    return (
      <>
        <div className="[ form-container ]">
          {doCapture && (
            <InstntDocumentProcessor
              documentSettings={frontLicenseSettings}
              documentType={idType}
              documentSide="Front"
              captureMode="Auto"
            />
          )}
          <div className="[ form-subtitle ]">
            {STRINGS.ABOUTYOU.PARTTWO.SUBTITILE}
          </div>
          <div className="[ form-description ]">
            <p>{STRINGS.ABOUTYOU.PARTTWO.ABOUTYOUINFO} </p>
            <p>
              <a
                className="[ link ]"
                href="#"
                role="button"
                onClick={() => this.handleOpenHelp()}
              >
                {STRINGS.ABOUTYOU.PARTTWO.ABOUTYOUINFO1}
              </a>
            </p>
          </div>
          <div className="[ form-description ]">
            <strong>{STRINGS.ABOUTYOU.PARTTWO.ABOUTYOUINFO2}</strong>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ row ] [ d-flex ] [ justify-content-center ]">
              <div className="[ col-12 ]">{imageDiv}</div>
              <div className="[ col-12 ]">{scanErrorDOM}</div>
            </div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ row ]">
              <div className="[ col-12 ]">
                <button
                  type="button"
                  className="[ btn ] [ retake ]"
                  onClick={this.scanFrontID}
                  disabled={capturedImage === null}
                >
                  {STRINGS.ABOUTYOU.RETAKE}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={() => this.submitData(imgSrc)}
              >
                <span className="[ d-md-none d-block ]">
                  {STRINGS.COMMON.CONTINUEBTN}
                </span>
                <span className="[ d-none d-md-block ]">
                  {STRINGS.ABOUTYOU.UPLOADANDCONTINUE}
                </span>
              </button>
            </div>
          </div>
        </div>
        <Modal show={showHelp} onHide={this.handleCloseHelp}>
          <Modal.Header
            className="[ popup-modal__header ]"
            closeButton={this.handleCloseHelp}
          >
            <Modal.Title>{STRINGS.ABOUTYOU.PARTTWO.POPUPTITLE}</Modal.Title>
          </Modal.Header>

          <Modal.Body className="[ overflow-auto ] [ popup-modal__body ]">
            <div className="[ row ]">
              <div className="[ col-12 ] [ id-scan-window-bg ]">
                <div className="[ id-container ]">
                  <div className=" [ id-scan-window ]"></div>
                  <div className=" [ id-box ]"></div>
                  <div className="[ id-card ]">
                    <div className="[ row ]">
                      <div className="[ col-6 ]">
                        <div className="[ id-pic ]"></div>
                      </div>
                      <div className="[ col-6 ]">
                        <hr />
                        <hr />
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="[ col-12 ]">
                <ul className="[ id-scan-tips ]">
                  <li>{STRINGS.ABOUTYOU.PARTTWO.POPUPINFO}</li>
                  <li>{STRINGS.ABOUTYOU.PARTTWO.POPUPINFO1}</li>
                  <li>{STRINGS.ABOUTYOU.PARTTWO.POPUPINFO2}</li>
                  <li>{STRINGS.ABOUTYOU.PARTTWO.POPUPINFO3}</li>
                  <li>{STRINGS.ABOUTYOU.PARTTWO.POPUPINFO4}</li>
                </ul>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

ScanIdFront.propTypes = {
  scanIdFrontHandler: PropTypes.func,
  idFront: PropTypes.string,
  idType: PropTypes.string,
};

ScanIdFront.defaultProps = {
  scanIdFrontHandler: () => {},
  idFront: null,
  idType: null,
};

export default ScanIdFront;
