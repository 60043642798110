import * as actionTypes from "../../../Redux/Actions/actionTypes";

export const doGetPasswordCriteria = () => {
  return {
    type: actionTypes.GET_PASSWORD_CRITERIA_REQUEST,
    data: null,
  };
};

export const doUpdatePassword = (userDetails) => {
  return {
    type: actionTypes.UPDATE_PASSWORD_REQUEST,
    data: userDetails,
  };
};
