import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { Container, Modal } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { STRINGS } from "./lib/Strings";
import { FIInformation } from "./lib/Constants";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEscapeModal: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleCloseEscape = () => {
    this.setState({
      showEscapeModal: false,
    });
  };

  handleShowEscape = () => {
    this.setState({
      showEscapeModal: true,
    });
  };

  endProcess = () => {
    const { history, goToPage } = this.props;
    sessionStorage.setItem("END_FLAG", "LEAVING");
    goToPage("/end");
  };

  render() {
    const { showEscapeModal } = this.state;
    const { atEnd, brand, language } = this.props;
    return (
      <>
        <Modal
          show={showEscapeModal}
          onHide={this.handleCloseEscape}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {STRINGS[brand][language].MODAL_LEAVING_APP.HEADER_TITLE}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {STRINGS[brand][language].MODAL_LEAVING_APP.BODY_0}
            {STRINGS[brand][language].MODAL_LEAVING_APP.BODY_1}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseEscape}>
              {STRINGS[brand][language].MODAL_LEAVING_APP.CANCEL_CTA}
            </Button>

            <Button variant="secondary" onClick={() => this.endProcess()}>
              {STRINGS[brand][language].MODAL_LEAVING_APP.LEAVE_CTA}
            </Button>
          </Modal.Footer>
        </Modal>
        <header>
          <Navbar bg="light" expand="lg">
            <Container fluid="sm" className="justify-content-center">
              {!atEnd && (
                <button
                  type="button"
                  className="close"
                  onClick={this.handleShowEscape}
                >
                  <span aria-hidden="true">x</span>
                  <span className="sr-only">
                    {STRINGS[brand][language].MODAL.CLOSE}
                  </span>
                </button>
              )}
              <a className="navbar-brand" href="#">
                <img
                  src={FIInformation[brand][language].FI_LOGO}
                  className="logo"
                  width="250"
                  height="40"
                  alt={FIInformation[brand][language].FI_FULLNAME}
                  title={FIInformation[brand][language].FI_FULLNAME}
                />
              </a>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default Header;
