export const TIMEOUT_DURATION = 10000;
export const GETTING_STARTED_API_KEY = "gettingStarted";
export const VAULT_API_KEY = "applicationprogress";
export const ATTEMPTS = 3;
export const MAX_ATTEMPTS = 10;

export const IDEVERIFICATION_AUTH = {
  VERIFIED: "Verified",
  VERIFIED_BUT_NOT_PASS: "Personal information verified but did not pass.",
  RECAPTURE_SELFIE: "Recapture Selfie",
  RECAPTURE_ID: "Recapture ID",
  RECAPTURE_ALL: "Recapture All",
  ID_EXPIRATION: "ID Expiration",
  FAILED: "Failed",
  UNSUPPORTED_ID: "Unsupported ID",
  POTENTIAL: "Potential Paper ID",
  SUBMISSION_ERROR: "Submission Error",
  FRAUD_SHIELD: "Fraud Shield",
  DOCUMENT_NOT_PASS: "Document did not pass",
  SELFIE_MATCH_NOT_PASS:
    "Selfie and the image in your document failed the comparison match. Please retake your selfie.",
};
export const IDVERIFICATION_FAILED_CODE = {
  INITIAL: {
    profile: true,
    selectId: true,
    captureFrontId: true,
    captureBackId: true,
    captureSelfie: true,
  },
  FINAL: {
    profile: true,
    selectId: false,
    captureFrontId: false,
    captureBackId: false,
    captureSelfie: false,
  },
  AU100: {
    profile: true,
    selectId: false,
    captureFrontId: true,
    captureBackId: true,
    captureSelfie: false,
  },
  AU101: {
    profile: true,
    selectId: false,
    captureFrontId: true,
    captureBackId: true,
    captureSelfie: false,
  },
  AU102: {
    profile: true,
    selectId: false,
    captureFrontId: true,
    captureBackId: true,
    captureSelfie: false,
  },
  AU103: {
    profile: true,
    selectId: false,
    captureFrontId: true,
    captureBackId: true,
    captureSelfie: false,
  },
  AU104: {
    profile: true,
    selectId: false,
    captureFrontId: false,
    captureBackId: false,
    captureSelfie: false,
  },
  AU105: {
    profile: true,
    selectId: false,
    captureFrontId: false,
    captureBackId: false,
    captureSelfie: false,
  },
  AU106: {
    profile: true,
    selectId: false,
    captureFrontId: true,
    captureBackId: true,
    captureSelfie: false,
  },
  AU122: {
    profile: true,
    selectId: true,
    captureFrontId: true,
    captureBackId: true,
    captureSelfie: false,
  },
};
